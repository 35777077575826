import React, { FC, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Form, FormGroup, FormControl, ControlLabel, Modal, Message, Loader, Schema, HelpBlock, InputPicker, TagPicker } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { EntityItemStore, NewEntityItem } from 'src/stores/entities-store';
import { formatMessage } from 'src/core/services/http.service';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import { LanguagesTabs } from 'src/core/ui/language/languages-tabs';
import { clone, create_UUID } from 'src/core/utils/object';
const { StringType } = Schema.Types;

interface NewEntityDialogProps {
    onClose: (result: boolean) => void
}

const NewEntityDialog: FC<{ collectionId: string } & WithTranslation & NewEntityDialogProps> = ({ t, collectionId, onClose }) => {
    const newEntityStore = container.get(EntityItemStore);
    newEntityStore.setCollection(collectionId);
    const newEntityState = newEntityStore.state;
    const isBusy = newEntityState.isBusy.value
    const errorMessage = newEntityState.errorMessage.value
    const form = useRef<any>();
    const [formValue, setFormValue] = useState<NewEntityItem>({ entityType: 'Synonyms' } as any)

    const [languages, setLanguages] = useState<string[]>(() => [])
    const [language, setLanguage] = useState(() => languages.length > 0 ? languages[0] : 'en');
    const samples = formValue && formValue.entityValues ? formValue.entityValues.filter(o=>o.language == language) : []

    const transformedName = useMemo(() => {
        let title = (formValue.title || "").replace(/\s\s+/g, ' ').toLowerCase();
        title = title.replace(/\s/gi, '-')
        title = title.replace(/ñ/gi, 'n')
        title = title.replace(/[^0-9a-z_-]/gi, '')
        return title;
    }, [formValue && formValue.title])

    const onNewItem = async () => {
        let validation = await form!.current!.checkAsync();
        if (validation && !validation.hasError) {
            formValue.title = transformedName
            let response = await newEntityStore.create(formValue);
            if (response) {
                setFormValue({} as any)
                onClose(true);
            }
        }
    };

    const model = Schema.Model({
        title: StringType().isRequired(t('The name of the entity is required.')),
        entityType: StringType().isRequired(t('Please write some body text.')),
    });

    const onAddLanguage = async (lang: string) => {
        const value = clone<NewEntityItem>(formValue)
        if (!value.entityValues) {
            value.entityValues = []
        }
        setLanguages([...languages, lang])
        setLanguage(lang);
        setFormValue(value)
        return true;
    }

    const onRemoveLanguage = async (lang: string) => {
        const value = clone<NewEntityItem>(formValue)
        const languages = Object.keys(value)
        if (languages.length <= 1)
            return false

        if (value && value.entityValues.filter(o=>o.language==lang).length > 0) {
            if (!await ConfirmDialog({
                text: t("Are you sure want to remove the '{0}' language? Content will be lost.").replace("{0}", lang),
                type: "warning",
                okLabel: t("Yes"),
                cancelLabel: t("No"),
            }))
                return false;
        }

        setLanguages(languages.filter(l => l !== lang));
        if (lang == language) {
            setLanguage(languages.length > 0 ? languages[0] : 'en');
        }
                
        value.entityValues = value.entityValues.filter(o => o.language != lang)
        setFormValue(value)
        return true;
    }

    const onChangeLanguage = async (lang: string) => {
        setLanguage(lang)
    }

    const onChangeEntityValues = (values: string[]) => {
        const value = clone<NewEntityItem>(formValue)
        value.entityValues = value.entityValues.filter(o => o.language != language)
        value.entityValues = [...value.entityValues, ...values.map(o => ({ language: language, value: o }))]
        setFormValue(value)
    }

    return (
        <Modal backdrop="static" show onHide={() => onClose(false)}>
            <Modal.Header>
                <h5>{t("New entity")}</h5>
            </Modal.Header>
            {formValue && <Modal.Body style={{ minHeight: '350px' }}>
                {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
                {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
                {formValue.title && transformedName !== formValue.title && <Message description={<span>{t('The title will be rewritten as:')} <b>{transformedName}</b></span>} />}
                {!isBusy && <Form fluid className="fluid-tooltip" model={model} ref={form} formValue={formValue} onChange={value => setFormValue(value as any)}>
                    <FormGroup>
                        <ControlLabel>{t("Title")}</ControlLabel>
                        <FormControl name="title" />
                        <HelpBlock tooltip>{t("The title of the entity")}</HelpBlock>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t("Type")}</ControlLabel>
                        <FormControl name="entityType" accepter={InputPicker} data={[{ value: 'Synonyms', label: t('Synonyms') }, { value: 'Regex', label: t('Regular Expression') }]} />
                        <HelpBlock tooltip>{t("Type of the entity. Regex or Synonyms")}</HelpBlock>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t("Samples")}</ControlLabel>
                        <LanguagesTabs selectedLanguages={languages} availableLanguages={['en', 'es', 'pt']} value={language} onChange={onChangeLanguage} onAddLanguage={onAddLanguage} onRemoveLanguage={onRemoveLanguage} />
                        {languages.length > 0 && <TagPicker name="audience" creatable data={samples.map(o => ({ value: o.value, label: o.value }))} value={samples.map(o=>o.value)} onChange={value => onChangeEntityValues(value)} />}
                    </FormGroup>
                </Form>}
            </Modal.Body>}
            <Modal.Footer>
                <Button disabled={isBusy} onClick={() => onNewItem()} appearance="primary">
                    {t("Create")}
                </Button>
                <Button disabled={isBusy} onClick={() => onClose(false)} appearance="subtle">
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const showNewEntityDialog = (collectionId: string) => {
    let TranslatedNewEntityDialog = withTranslation()(NewEntityDialog);
    return new Promise((response, reject) => {
        let div = document.createElement('div');
        let modalRoot = document.getElementById("modal-root");
        ReactDOM.render(<TranslatedNewEntityDialog collectionId={collectionId} onClose={(result: boolean) => {
            response(result);
            ReactDOM.unmountComponentAtNode(div);
            modalRoot!.removeChild(div);
        }} />, div);
        modalRoot!.appendChild(div);
    });
};

export default showNewEntityDialog