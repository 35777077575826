import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { container } from 'src/inversify.config';
import { SynonymsItemStore } from 'src/stores/synonyms-store';
import { Button, Panel, Icon, Loader, Message, FormGroup, FormControl, HelpBlock, TagPicker, ControlLabel, Tooltip, Whisper, Input, Alert } from 'rsuite';
import { formatMessage } from 'src/core/services/http.service';
import PageContainer from 'src/core/ui/frame/page-content';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import { Region } from 'src/core/ui/region/region';
import { none } from '@hookstate/core';

const SynonymssPage: FC<RouteComponentProps<{ synonymsId: string }> & WithTranslation> = ({ t, match }) => {
    const synonymsStore = container.get(SynonymsItemStore)
    const synonymsState = synonymsStore.state
    const isBusy = synonymsState.isBusy.value
    const errorMessage = synonymsState.errorMessage.value
    const synonymsId = match.params.synonymsId
    const title = synonymsState.item.value && synonymsState.item.title.value || t("Synonyms")

    const onSave = async () => {
        await synonymsStore.save(synonymsId, synonymsState.item.value)
        Alert.info(t('Synonyms list has been saved successfully!'));
    }

    useEffect(() => {
        synonymsStore.load(synonymsId)
    }, [synonymsId]);

    const onReload = async () => {
        await synonymsStore.load(synonymsId)
    }

    const onAddTransformation = () => {
        const language = synonymsState.item.synonymTransformers.value && synonymsState.item.synonymTransformers.value.length > 0 ? synonymsState.item.synonymTransformers.value[synonymsState.item.synonymTransformers.value.length - 1].language : 'en'
        const sample = {
            language: language,
            target: "",
            source: []
        }
        if (synonymsState.item.synonymTransformers.value) {
            synonymsState.item.synonymTransformers[synonymsState.item.synonymTransformers.length].set(sample)
        } else {
            synonymsState.item.synonymTransformers.set([sample])
        }
    };

    return (
        <PageContainer title={<div><Icon icon="language" />&nbsp;<span>{title}</span></div>}
            toolbarLeft={
                [
                    <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload this document")}</Tooltip>}>
                        <Button placement="left" onClick={() => onReload()}>
                            <Icon icon="reload" />
                        </Button>
                    </Whisper>
                ]
            }
            toolbarRight={
                [
                    <Whisper key='save' placement="top" trigger="hover" speaker={<Tooltip>{t("Save this document")}</Tooltip>}>
                        <Button placement="left" appearance="primary" onClick={() => onSave()}>
                            <Icon icon="save" /> {t("Save")}
                        </Button>
                    </Whisper>
                ]
            }>
            {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
            {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
            
            {synonymsState.item.value && synonymsState.item.synonymTransformers.value && synonymsState.item.synonymTransformers.map((syn, index) => (
                <Panel key={index} className="card" style={{ marginBottom: 10 }} bordered>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'flex-start' }}>
                        <FormGroup style={{ margin: 10, width: 250 }}>
                            <ControlLabel>{t("Replace with")}</ControlLabel>
                            <Input style={{fontWeight: 'bold'}} name="name" value={syn.target.value} onChange={val => syn.target.set(val)} />
                        </FormGroup>
                        <FormGroup style={{ margin: 10, flexGrow: 1 }}>
                            <ControlLabel>{t("These phrases or terms")}</ControlLabel>
                            <TagPicker
                                name='synonyms'
                                creatable
                                style={{ minWidth: 300, width: '100%' }}
                                data={syn.source.map(o => ({ label: o.value, value: o.value }))}
                                value={syn.source.value}
                                onChange={val => {
                                    syn.source.set(val);
                                }}
                                placeholder={ t('Write a word or a phrase')}
                            />
                        </FormGroup>
                        <div style={{ float: 'right' }}>
                            <Whisper
                                placement='left'
                                speaker={
                                    <Tooltip>{t("Remove this transformation from the synonyms list")}</Tooltip>
                                }>
                                <Button appearance="subtle" onClick={() => {
                                    ConfirmDialog({
                                        text: <span>{t("Are you sure want to remove the following transformation?")} <b>{syn.target.value}</b></span>,
                                        type: "warning",
                                        okLabel: t("Yes"),
                                        cancelLabel: t("No"),
                                    }).then(result => {
                                        if (result) {
                                            syn.set(none)
                                        }
                                    });
                                }}><Icon icon="trash-o" /></Button>
                            </Whisper>
                        </div>
                    </div>
                </Panel>
            ))}
            {synonymsState.item.value && (!synonymsState.item.synonymTransformers.value || synonymsState.item.synonymTransformers.length == 0) && <Region>
                <p>{t("This list is empty. Please")}&nbsp;                                
                   <Button appearance="link" onClick={() => onAddTransformation()} style={{ padding: 0, margin: 0 }}>{t("click here to add your first synonyms transformation")}</Button>
                </p>
            </Region>}
            {synonymsState.item.value && synonymsState.item.synonymTransformers.value.length>0 && <Button appearance="link" style={{ float: "right" }} onClick={() => onAddTransformation()}>{t("Add new transformation")}</Button>}
        </PageContainer>
    )
}

export default withTranslation()(withRouter(SynonymssPage))