import React, { FC } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import EntityDetailPage from './entity-detail';

const EntitiesIndex: FC<RouteComponentProps<any>> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.path}/:entityId`} component={EntityDetailPage} />
    </Switch>
}

export default withRouter(EntitiesIndex)