import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';
import { IdentityReference } from './common-types';


export interface DocumentSummary {
    id: string
    title: string
    contentType: string
    status: string
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface Entity {
    type: string
    text: string
    value: string
    value2: string
    unit: string
}

export interface Intent {
    text: string
    suggest: boolean
}

export interface AttachmentItem {
    fileName: string,
    contentUri?: string,
    content?: string,
    contentType: string,
}

export interface DocumentItem {
    id: string
    title: string
    boost: number
    contentType: string
    status: string
    dontAdvertise: boolean
    language: string
    summary: string
    audience: string[]
    question: string[]
    attachments: AttachmentItem[]
    intents: Intent[]
    body: string
    entities: Entity[]
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface NewDocumentItem {
    title: string
    body: string
    contentType: string
    audience: string[]
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export class DocumentSummaryStore extends DataStore<DocumentSummary> {
    private realBaseUrl: string

    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/documents`, []);
        this.realBaseUrl = baseUrl
    }

    public async setCollection(id: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${id}/documents`
    }

    public async reprocess(collectionId: string) {
        return await this.handleCallAsync(async () => {
            await this.httpService.post<any, any>(`${this.realBaseUrl}/api/collections/${collectionId}/process`, {});
            return true;
        })
    }
}

export class DocumentItemStore extends FormStore<DocumentItem, NewDocumentItem> {
    private realBaseUrl: string
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/documents`);
        this.realBaseUrl = baseUrl
    }

    public async setCollection(collectionId: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${collectionId}/documents`
    }

    public async getLogs(documentId: string) {
        return await this.handleCallAsync(async () => {
            var response = await this.httpService.get<string>(`${this.baseUrl}/${encodeURIComponent(documentId)}/log`);
            return response.data;
        })
    }

    public async processDocument(documentId: string) {
        return await this.handleCallAsync(async () => {
            await this.httpService.post<any, any>(`${this.baseUrl}/${encodeURIComponent(documentId)}/process`, {});
            return true;
        })
    }

    public async getDocumentAttachmentLink(documentId: string, filename: string) {
        return await this.handleCallAsync(async () => {
            const response = await this.httpService.post<any, any>(`${this.baseUrl}/${encodeURIComponent(documentId)}/attachments/${filename}/link`, {});
            return response.data.item;
        })
    }

    public async removeAttachment(documentId: string, fileName: string) {
        return await this.handleCallAsync(async () => {
            if (fileName) 
                await this.httpService.delete<any, any>(`${this.baseUrl}/${encodeURIComponent(documentId)}/attachments/${fileName}`, {});
            else
                await this.httpService.delete<any, any>(`${this.baseUrl}/${encodeURIComponent(documentId)}/attachments`, {});
            return true;
        })
    }
}