import React, { createRef, FC, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { container } from 'src/inversify.config';
import { CrawlerItemStore } from 'src/stores/crawlers-store';
import { Alert, Button, ControlLabel, Table, Form, FormControl, FormGroup, HelpBlock, Icon, Input, List, Loader, Message, Nav, Schema, Tag, TagPicker, Toggle, Tooltip, Whisper } from 'rsuite';
import { formatMessage } from 'src/core/services/http.service';
import { BreadcrumbProvider } from 'src/core/stores/breadcrumb-store';
import PageContainer from 'src/core/ui/frame/page-content';
import CrawlerPatches from './patches/crawler-patches-list';
import CrawlerLogsPage from './logs/crawler-logs-list';
import { clone, nameof } from 'src/core/utils/object';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import { LanguagesPicker } from 'src/core/ui/language/languages-picker';
import { Region } from 'src/core/ui/region/region';

const { StringType } = Schema.Types;
const { Column, HeaderCell, Cell } = Table;

interface ServiceNowKbConfiguration {
    name: string
    id: string
    audiences: string[]
}

interface ServiceNowSettings {
    serviceUri: string
    username: string
    password: string
    validLanguages: string[]
    validKBs: ServiceNowKbConfiguration[]
}

export type CrawlerConfigRef = {
    save: () => Promise<boolean>,
    hasChanges: () => boolean
}

const ServiceNowConfig = React.forwardRef<CrawlerConfigRef, { value: string, onChange: (value: string) => void, t: any }>(({ value, onChange, t }, ref) => {
    const form = useRef<any>();
    const [formValue, setFormValue] = useState<ServiceNowSettings>((value && value != "") ? JSON.parse(value) : {})

    useEffect(() => setFormValue((value && value != "") ? JSON.parse(value) as ServiceNowSettings : {} as ServiceNowSettings), [value]);

    useImperativeHandle(ref,
        () => ({
            save: async () => {
                let validation = await form!.current!.checkAsync();
                if (validation && !validation.hasError) {
                    onChange(JSON.stringify(formValue))
                    return true;
                }
                return false;
            },
            hasChanges: () => {
                return JSON.stringify(formValue) !== value;
            }
        }));

    const model = Schema.Model({
        serviceUri: StringType().isRequired(t('The service URI is required.')),
    });

    const onAddNewKb = () => {
        let newValue = clone<ServiceNowSettings>(formValue)
        if (!newValue.validKBs)
            newValue.validKBs = []
        newValue.validKBs.push({
            id: "",
            name: "",
            audiences: []
        });
        setFormValue(newValue)
    }

    const onUpdateKb = (prop: string, index: number, value: any) => {
        let newValue = clone<any>(formValue);
        newValue!.validKBs[index][prop] = value;
        setFormValue(newValue)
    }

    const onRemoveKb = (index: number) => {
        let newValue = clone<any>(formValue);
        newValue.validKBs = newValue.validKBs.filter((o: any, idx: number) => idx != index);
        setFormValue(newValue)
    }

    const triggerRef = React.createRef<any>();

    return (<Form fluid className="fluid-tooltip" model={model} ref={form} formValue={formValue} onChange={value => setFormValue(value as any)}>
        <FormGroup>
            <ControlLabel>{t("Service Uri")}</ControlLabel>
            <FormControl name={nameof<ServiceNowSettings>("serviceUri")} />
            <HelpBlock tooltip>{t("The ServiceNow tenant URI. e.g https://[tenant].service-now.com")}</HelpBlock>
        </FormGroup>
        <div style={{ display: 'flex' }}>
            <FormGroup style={{ flexGrow: .95 }}>
                <ControlLabel>{t("Username")}</ControlLabel>
                <FormControl name={nameof<ServiceNowSettings>("username")} />
                <HelpBlock tooltip>{t("The username you want to use to authenticate in ServiceNow")}</HelpBlock>
            </FormGroup>
            <FormGroup style={{ flexGrow: .95 }}>
                <ControlLabel>{t("Password")}</ControlLabel>
                <FormControl type="password" name={nameof<ServiceNowSettings>("password")} />
                <HelpBlock tooltip>{t("The password you want to use to authenticate in ServiceNow")}</HelpBlock>
            </FormGroup>
        </div>
        <FormGroup>
            <ControlLabel>{t("Languages to crawl")}</ControlLabel>
            <FormControl accepter={LanguagesPicker} name={nameof<ServiceNowSettings>("validLanguages")} />
            <HelpBlock tooltip>{t("The languages you want to crawl. Only document that matches those languages will be crawled.")}</HelpBlock>
        </FormGroup>
        <FormGroup>
            <ControlLabel><span>{t("Knowledge bases to crawl")}</span><Button appearance='subtle' onClick={onAddNewKb}><Icon icon="plus" /></Button></ControlLabel>
            <Table data={formValue.validKBs || []} renderEmpty={() => {
                return (<Region>
                    <p>{t("You don't have any KB added yet.")}</p>
                </Region>)
            }}>                
                <Column width={250} fixed>
                    <HeaderCell>{ t("KB Id")}</HeaderCell>
                    <Cell style={{ padding: 0 }}>
                        {(row: ServiceNowKbConfiguration, index: number) => (<Input style={{ width: '100%' }} value={row.id} onChange={value => onUpdateKb(nameof<ServiceNowKbConfiguration>('id'), index, value) } />)}
                    </Cell>
                </Column>
                <Column width={250} fixed>
                    <HeaderCell>{ t("KB name")}</HeaderCell>
                    <Cell style={{ padding: 0 }}>
                        {(row: ServiceNowKbConfiguration, index: number) => (<Input style={{ width: '100%' }} value={row.name} onChange={value => onUpdateKb(nameof<ServiceNowKbConfiguration>('name'), index, value)} />)}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>{t("Audience")}</HeaderCell>
                    <Cell style={{ padding: 0 }}>
                        {(row: ServiceNowKbConfiguration, index: number) => (<TagPicker creatable data={(row.audiences || []).map(o => ({ label: o, value: o }))} value={row.audiences || []} onChange={value => onUpdateKb(nameof<ServiceNowKbConfiguration>('audiences'), index, value)} style={{ width: '100%' }} />)}
                    </Cell>
                </Column>
                <Column width={50} fixed="right">
                    <HeaderCell></HeaderCell>
                    <Cell style={{ padding: 0 }}>
                        {(row: ServiceNowKbConfiguration, index: number) => {
                            function handleAction() {
                                ConfirmDialog({
                                    text: t("Are you sure want to delete this KB?"),
                                    type: "warning",
                                    okLabel: t("Yes"),
                                    cancelLabel: t("No"),
                                }).then(async result => {
                                    if (result) {
                                        onRemoveKb(index);
                                    }
                                });
                            }
                            return (
                                <span>                                
                                    <Button appearance='subtle' onClick={handleAction}><Icon icon='trash-o' /></Button>
                                </span>
                            );
                        }}
                    </Cell>
                </Column>
            </Table>
        </FormGroup>
    </Form>)
})

const CrawlersPage: FC<RouteComponentProps<{ collectionId: string, crawlerId: string }> & WithTranslation> = ({ t, match }) => {
    const breadcrumbProvider = container.get(BreadcrumbProvider)
    const crawlerStore = container.get(CrawlerItemStore)
    const collectionId = match.params.collectionId
    crawlerStore.setCollection(collectionId)
    const crawlerState = crawlerStore.state
    const isBusy = crawlerState.isBusy.value
    const errorMessage = crawlerState.errorMessage.value
    const crawlerId = match.params.crawlerId
    const title = crawlerState.item.value && crawlerState.item.title.value || t("Crawler")
    const [activeTab, setActiveTab] = useState("general")
    const settingsRef = createRef<CrawlerConfigRef>();
    const onReload = async () => {
        await crawlerStore.load(crawlerId)
    }

    const onSave = async () => {
        const isValid = await settingsRef.current?.save();
        if (isValid) {
            await crawlerStore.save(crawlerId, crawlerState.item.value);
            await onReload();
        }
    }

    useEffect(() => {
        breadcrumbProvider.setTitle(match.url, title)
        const uri = match.url.split('/').slice(0, -1).join("/")
        breadcrumbProvider.setTitle(uri, "")
    }, [title]);

    useEffect(() => {
        onReload()
    }, [collectionId, crawlerId]);

    const onRunCrawler = async () => {
        ConfirmDialog({
            text: t("Are you sure want to start the crawler? This might take hours and will consume a lot of server resources."),
            type: "warning",
            okLabel: t("Yes"),
            cancelLabel: t("No"),
        }).then(async result => {
            if (result) {
                await crawlerStore.runCrawler(crawlerState.item.id.value)
                if (!errorMessage) {
                    Alert.success(t("Start crawler request was sent successfully"))
                }
            }
        });
    }

    const getCrawlerStatus = () => {
        if (!crawlerState.item.value)
            return <div>{t("Loading...")}</div>
        switch (crawlerState.item.status.value) {
            case 'Finished':
                return <Tag color="green">{t("Finished")}</Tag>
            case 'Error':
                return <Tag color="red">{t("Error")}</Tag>
            case 'Idle':
                return <Tag color="yellow">{t("Pending")}</Tag>
            case 'Running':
                return <Tag color="yellow">{t("Running")}</Tag>
            default:
                return <Tag>{t(crawlerState.item.status.value)}</Tag>
        }
    }

    return (
        <PageContainer title={<div><Icon icon="flow" />&nbsp;<span>{title}</span></div>}
            toolbarLeft={[<Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload this document")}</Tooltip>}>
                <Button placement="left" onClick={() => onReload()}>
                    <Icon icon="reload" />
                </Button>
            </Whisper>,]}
            toolbarRight={[
                <Whisper key='process' placement="top" trigger="hover" speaker={<Tooltip>{t("Run crawler")}</Tooltip>}>
                    <Button placement="left" onClick={() => onRunCrawler()}>
                        <Icon icon="play-circle" />
                    </Button>
                </Whisper>,
                <Whisper key='save' placement="top" trigger="hover" speaker={<Tooltip>{t("Save this document")}</Tooltip>}>
                    <Button placement="left" appearance="primary" onClick={() => onSave()}>
                        <Icon icon="save" /> {t("Save")}
                    </Button>
                </Whisper>
            ]}>
            {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
            {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
            {!isBusy && <div>
                <Nav appearance="tabs" activeKey={activeTab} onSelect={value => setActiveTab(value)}>
                    <Nav.Item eventKey="general"><Icon icon="flow" /> {t("Crawler")}</Nav.Item>
                    <Nav.Item eventKey="patches"><Icon icon="related-map" /> {t("Patches")}</Nav.Item>
                    <Nav.Item eventKey="history"><Icon icon="logo-dmp" /> {t("History")}</Nav.Item>
                </Nav>
            </div>}
            {activeTab == 'general' && <div style={{ paddingTop: 10 }}>
                {crawlerState.item.value && crawlerState.item.crawlerType.value == "ServiceNow" && <div style={{ padding: 10}}>
                    <Form>
                        <FormGroup style={{ flexGrow: .95 }}>
                            <ControlLabel>{t("Schedule")}</ControlLabel>
                            <FormControl value={crawlerState.item.schedule.value} onChange={value => crawlerState.item.schedule.set(value)} />
                            <HelpBlock tooltip>{t("A cron expression (Minute Hour Day Month Day-of-Week) like '0 1 * * *'")}</HelpBlock>&nbsp;
                            <span>{t("View more info at")}: <a href="https://en.wikipedia.org/wiki/Cron#CRON_expression" target="_new">https://en.wikipedia.org/wiki/Cron#CRON_expression</a></span>
                        </FormGroup>
                    </Form>
                    <ServiceNowConfig ref={settingsRef} value={crawlerState.item.settings.value} onChange={value => crawlerState.item.settings.set(value)} t={t} />
                </div>}
            </div>}
            {activeTab == 'history' && <div>
                <CrawlerLogsPage collectionId={collectionId} crawlerId={crawlerId} />
            </div>}
            {activeTab == 'patches' && <div>
                <CrawlerPatches collectionId={collectionId} crawlerId={crawlerId} />
            </div>}
        </PageContainer>
    )
}

export default withTranslation()(withRouter(CrawlersPage))