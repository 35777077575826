import React, { FC, useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
//import { setHeader } from 'src/core/ui/shell';
import { clone, formatDate, formatDateTime, formatTimeSpent } from 'src/core/utils/object';
import { container } from 'src/inversify.config';
import { SynonymsSummary, SynonymsSummaryStore } from 'src/stores/synonyms-store';
import { Query } from 'src/core/stores/data-store';
import { Button, Icon, InputGroup, Message, Tooltip, Whisper } from 'rsuite';
import { TableModel } from 'src/core/ui/table/table-model';
import { formatMessage } from 'src/core/services/http.service';
import Table from 'src/core/ui/table';
import PageContainer from 'src/core/ui/frame/page-content';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import SearchBox from 'src/core/ui/form/search-box';
import showNewSynonymsDialog from './synonyms-new';
import { Link } from 'react-router-dom';
import ContextMenu from 'src/core/ui/table/context-menu';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';


const SynonymssPage: FC<RouteComponentProps<any> & WithTranslation> = ({ t, ...props }) => {
    //setHeader(t("Synonymss"))
    const synonymssStore = container.get(SynonymsSummaryStore)
    const synonymsState = synonymssStore.state
    const [query, setQuery] = useState({
        searchQuery: '',
        orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 50
    } as Query)

    const onChange = async (query: Query) => {
        synonymssStore.load(query)
        setQuery(query)
    }

    const onSearch = async (q?: string) => {
        let newQuery = clone(query || {}) as Query;
        newQuery.skip = 0;
        newQuery.searchQuery = q || "";
        await onChange(newQuery);
    }

    const onNewSynonyms = async () => {
        let result = await showNewSynonymsDialog()
        await onChange(query);
    }

    const onDeleteDocument = async (synonymsId: string) => {
        await synonymssStore.delete(synonymsId)
    }

    useEffect(() => {
        synonymssStore.load(query)
    }, [query]);

    const tableModel = {
        query: query,
        loading: synonymsState.isBusy.value,
        columns: [
            {
                field: 'icon',
                width: 40,
                title: "",
                renderer: (item) => <Icon icon="language" />
            },
            {
                field: 'title',
                title: t('Title'),
                flow: 1,
                renderer: (item) => <Link to={`/synonyms/${item.id}`}><span>{item.title}</span></Link>
            },
            {
                field: 'createdBy',
                title: t('Created by'),
                renderer: (item) => <span>{item.createdBy && item.createdBy.identityDisplayName}</span>
            },
            {
                field: 'createdOn',
                title: t('Created on'),
                renderer: (item) => <span>{formatDate(item.createdOn)}</span>
            },
            {
                field: 'modifiedBy',
                title: t('Modified by'),
                renderer: (item) => <span>{item.modifiedBy && item.modifiedBy.identityDisplayName}</span>
            },
            {
                field: 'modifiedOn',
                title: t('Modified on'),
                renderer: (item) => <span>{formatDate(item.modifiedOn)}</span>
            },
            {
                field: 'actions',
                width: 60,
                title: "",
                renderer: (item) => <ContextMenu
                    entries={[
                        //{
                        //    title: <div><Icon icon="download" /> {t("Download a copy")}</div>,
                        //    onSelect: () => {

                        //    }
                        //},
                        //{ divider: true },
                        {
                            title: <div><Icon icon="trash2" /> {t("Delete synonyms")}</div>, onSelect: () => {
                                ConfirmDialog({
                                    text: t("Are you sure want to delete the selected synonyms?"),
                                    type: "warning",
                                    okLabel: t("Yes"),
                                    cancelLabel: t("No"),
                                }).then(result => {
                                    if (result) {
                                        onDeleteDocument(item.id);
                                    }
                                });
                            }
                        },
                    ]}>
                    <Link to="#"><Icon icon="more" /></Link>
                </ContextMenu>
            },
        ],
        data: synonymsState,
        paginable: false
    } as TableModel<SynonymsSummary>;
    
    return (
        <PageContainer
            title={t("Synonyms Lists")}
            toolbarLeft={
                [
                    <Whisper key='newSynonyms' placement="top" trigger="hover" speaker={<Tooltip>{t("New synonyms")}</Tooltip>}>
                        <Button placement="left" onClick={onNewSynonyms} appearance="primary">
                            <Icon icon="plus" />
                        </Button>
                    </Whisper>,
                    <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload list")}</Tooltip>}>
                        <Button placement="left" onClick={() => onChange(query)}>
                            <Icon icon="reload" />
                        </Button>
                    </Whisper>
                ]
            }
            toolbarRight={
                [
                    <FlexboxGridItem key="search">
                        <InputGroup inside>
                            <SearchBox key='searchBox' onSearch={onSearch} />
                        </InputGroup>
                    </FlexboxGridItem>
                ]
            }>
            {synonymsState.errorMessage.value && <Message type="error" description={formatMessage(synonymsState.errorMessage.value)} />}
                <Table model={tableModel} onChange={onChange} />
        </PageContainer>
    )
}

export default withTranslation()(withRouter(SynonymssPage))