import { Icon, Input, InputGroup } from 'rsuite';
import React, { FC, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface SearchBoxProps {
    onSearch: (q?: string) => void;
}

const SearchBox: FC<SearchBoxProps & WithTranslation> = ({ t, onSearch }) => {
    const [value, setValue] = useState("")
    return (
        <React.Fragment>
            <Input key="search" placeholder={t('Search')} onChange={(value) => setValue(value as any)} onPressEnter={(e) => onSearch(value)} />
            <InputGroup.Addon key="search-addon" >
                <Icon icon="search" onClick={() => onSearch(value)} />
            </InputGroup.Addon>
        </React.Fragment>
    );
}

export default withTranslation()(SearchBox);