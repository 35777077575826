import React, { FC, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Form, FormGroup, FormControl, ControlLabel, Modal, Message, Loader, Schema, HelpBlock } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { SynonymsItemStore, NewSynonymsItem } from 'src/stores/synonyms-store';
import { formatMessage } from 'src/core/services/http.service';
const { StringType } = Schema.Types;

interface NewSynonymsDialogProps {
    onClose: (result: boolean) => void
}

const NewSynonymsDialog: FC<WithTranslation & NewSynonymsDialogProps> = ({ t, onClose }) => {
    const newProjectStore = container.get(SynonymsItemStore)
    const newProjectState = newProjectStore.state;
    const isBusy = newProjectState.isBusy.value
    const errorMessage = newProjectState.errorMessage.value
    const form = useRef<any>();
    const [formValue, setFormValue] = useState<NewSynonymsItem>({} as any)
    
    const onNewItem = async () => {
        let validation = await form!.current!.checkAsync();
        if (validation && !validation.hasError) {
            let response = await newProjectStore.create(formValue);
            if (response) {
                setFormValue({} as any)
                onClose(true);
            }
        }
    };

    const model = Schema.Model({
        title: StringType().isRequired(t('The name of the synonyms list is required.'))
    });

    return (
        <Modal backdrop="static" show onHide={() => onClose(false)}>
            <Modal.Header>
                <h5>{t("New synonyms list")}</h5>
            </Modal.Header>
            <Modal.Body>
                {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
                {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
                {!isBusy && <Form fluid className="fluid-tooltip" model={model} ref={form} formValue={formValue} onChange={value => setFormValue(value as any)}>
                    <FormGroup>
                        <ControlLabel>{t("Title")}</ControlLabel>
                        <FormControl name="title" />
                        <HelpBlock tooltip>{t("The name of the synonyms list you want to create")}</HelpBlock>
                    </FormGroup>
                </Form>}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isBusy} onClick={() => onNewItem()} appearance="primary">
                    {t("Create")}
                </Button>
                <Button disabled={isBusy} onClick={() => onClose(false)} appearance="subtle">
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const showNewSynonymsDialog = () => {
    let TranslatedNewSynonymsDialog = withTranslation()(NewSynonymsDialog);
    return new Promise((response, reject) => {
        let div = document.createElement('div');
        let modalRoot = document.getElementById("modal-root");
        ReactDOM.render(<TranslatedNewSynonymsDialog onClose={(result: boolean) => {
            response(result);
            ReactDOM.unmountComponentAtNode(div);
            modalRoot!.removeChild(div);
        }} />, div);
        modalRoot!.appendChild(div);
    });
};

export default showNewSynonymsDialog