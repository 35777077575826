import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';
import { IdentityReference } from './common-types';

export interface CrawlerPatchSummary {
    id: string
    documentId: string
    title: string
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface CrawlerPatchItem {
    id: string
    documentId: string
    title: string
    patch: any
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export class CrawlerPatchesSummaryStore extends DataStore<CrawlerPatchSummary> {
    private realBaseUrl: string

    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/crawlers/undefined/patches`, []);
        this.realBaseUrl = baseUrl
    }
    public async setCrawler(collectionId: string, crawlerId: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${collectionId}/crawlers/${crawlerId}/patches`
    }
}

export class CrawlerPatchesItemStore extends FormStore<CrawlerPatchItem, CrawlerPatchItem> {
    private realBaseUrl: string
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/crawlers/undefined/patches`);
        this.realBaseUrl = baseUrl
    }
    public async setCrawler(collectionId: string, crawlerId: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${collectionId}/crawlers/${crawlerId}/patches`
    }
}