import React, { FC, useEffect, useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { container } from 'src/inversify.config';
import { EntityItem, EntityItemStore } from 'src/stores/entities-store';
import { InputPicker, Alert, Button, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, HelpBlock, Icon, Input, List, Loader, Message, Nav, Tag, TagPicker, Toggle, Tooltip, Whisper } from 'rsuite';
import { formatMessage } from 'src/core/services/http.service';
import { BreadcrumbProvider } from 'src/core/stores/breadcrumb-store';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import PageContainer from 'src/core/ui/frame/page-content';
import { LanguagesTabs } from 'src/core/ui/language/languages-tabs';
import { none } from '@hookstate/core';
import { lang } from 'moment';

const EntitiesPage: FC<RouteComponentProps<{ collectionId: string, entityId: string }> & WithTranslation> = ({ t, match }) => {
    const breadcrumbProvider = container.get(BreadcrumbProvider)
    const entityStore = container.get(EntityItemStore)
    const collectionId = match.params.collectionId
    entityStore.setCollection(collectionId)
    const entityState = entityStore.state
    const isBusy = entityState.isBusy.value
    const errorMessage = entityState.errorMessage.value
    const entityId = match.params.entityId
    const title = entityState.item.value && entityState.item.title.value || t("Entity")

    const [languages, setLanguages] = useState<string[]>(() => [])
    const [language, setLanguage] = useState(() => languages.length > 0 ? languages[0] : 'en');
    const samples = entityState.item.value && entityState.item.entityValues.value ? entityState.item.entityValues.filter(o=>o.language.value==language) : []

    useEffect(() => {
        breadcrumbProvider.setTitle(match.url, title)
        const uri = match.url.split('/').slice(0, -1).join("/")
        breadcrumbProvider.setTitle(uri, "")
    }, [title]);

    useEffect(() => {
        onReload()
    }, [collectionId, entityId]);

    useEffect(() => {
        const langs = entityState.item.value && entityState.item.entityValues.value ? entityState.item.entityValues.value.groupBy(o => o.language).map(o => o.key) : []
        setLanguages(langs)
        setLanguage(langs.length > 0 ? langs[0] : 'en')
    }, [entityState.isBusy.value]);

    const onReload = async () => {
        await entityStore.load(entityId)
    }

    const onSave = async () => {
        await entityStore.save(entityId, entityState.item.value);
        await onReload();
    }

    const onAddLanguage = async (lang: string) => {
        setLanguages([...languages, lang])
        setLanguage(lang);
        return true;
    }

    const onRemoveLanguage = async (lang: string) => {
        if (languages.length <= 1)
            return false

        const samples = entityState.item.value && entityState.item.entityValues.value ? entityState.item.entityValues.filter(o => o.language.value == lang) : []

        if (samples.length > 0) {
            if (!await ConfirmDialog({
                text: t("Are you sure want to remove the '{0}' language? Content will be lost.").replace("{0}", lang),
                type: "warning",
                okLabel: t("Yes"),
                cancelLabel: t("No"),
            }))
                return false;
        }

        setLanguages(languages.filter(l => l !== lang));
        samples.forEach(sample => sample.set(none));
        if (lang == language) {
            setLanguage(languages.length > 0 ? languages[0] : 'en');
        }

        return true;
    }

    const onChangeLanguage = async (lang: string) => {
        setLanguage(lang)
    }

    const onChangeEntityValues = (values: string[]) => {
        values.forEach(newValue => {
            var existing = entityState.item.entityValues.filter(o => o.value.value == newValue && o.language.value == language)
            if (existing.length == 0)
                entityState.item.entityValues[entityState.item.entityValues.length].set({ language: language, value: newValue })
        })

        entityState.item.entityValues.forEach(oldValue => {
            if (oldValue.language.value == language) {
                var existing = values.filter(o => o == oldValue.value.value)
                if (existing.length == 0)
                    oldValue.set(none)
            }
        })
    }

    return (
        <PageContainer title={<div><Icon icon="flow" />&nbsp;<span>{title}</span></div>}
            toolbarLeft={[<Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload this document")}</Tooltip>}>
                <Button placement="left" onClick={() => onReload()}>
                    <Icon icon="reload" />
                </Button>
            </Whisper>,]}
            toolbarRight={[<Whisper key='save' placement="top" trigger="hover" speaker={<Tooltip>{t("Save this document")}</Tooltip>}>
                <Button placement="left" appearance="primary" onClick={() => onSave()}>
                    <Icon icon="save" /> {t("Save")}
                </Button>
            </Whisper>]}>
            {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
            {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
            {entityState.item.value && <Form fluid className="fluid-tooltip">
                <FormGroup>
                    <ControlLabel>{t("Title")}</ControlLabel>
                    <FormControl name="title" readOnly value={entityState.item.title.value} onChange={val => entityState.item.title.set(val)}/>
                    <HelpBlock tooltip>{t("The title of the entity")}</HelpBlock>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{t("Type")}</ControlLabel>
                    <FormControl name="entityType" readOnly accepter={InputPicker} data={[{ value: 'Synonyms', label: t('Synonyms') }, { value: 'Regex', label: t('Regular Expression') }]} value={entityState.item.entityType.value} onChange={val=>entityState.item.entityType.set(val)} />
                    <HelpBlock tooltip>{t("Type of the entity. Regex or Synonyms")}</HelpBlock>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>{t("Samples")}</ControlLabel>
                    <LanguagesTabs selectedLanguages={languages} availableLanguages={['en', 'es', 'pt']} value={language} onChange={onChangeLanguage} onAddLanguage={onAddLanguage} onRemoveLanguage={onRemoveLanguage} />
                    {languages.length > 0 && <TagPicker name="samples" placeholder={ t("Start writing your samples...")} creatable data={samples.map(o => ({ value: o.value.value, label: o.value.value }))} value={samples.map(o=>o.value.value)} onChange={value => onChangeEntityValues(value)} />}
                </FormGroup>
            </Form>}
        </PageContainer>
    )
}

export default withTranslation()(withRouter(EntitiesPage))