import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FlexboxGrid } from 'rsuite';
import CodeEditor from 'src/core/ui/form/code-editor';
import { CollectionItem } from 'src/stores/collections-store';
import { State } from '@hookstate/core';
import yaml from 'js-yaml';


const ParsersPage: FC<{ collection: State<CollectionItem>, changed: boolean, setChanged: (arg: boolean) => void } & WithTranslation> = ({ t, collection, changed, setChanged }) => {

    const [yamlValue, setYamlValue] = useState("")

    useEffect(() => {
        const jsonValue = (collection.processors.value && collection.processors.length > 0) ? collection.processors[0].definition.value : ""
        const jsonObj = jsonValue ? yaml.load(jsonValue) : {}
        setYamlValue(yaml.dump(jsonObj))
    }, [])

    const onChangeValue = (value?: string) => {
        setYamlValue(value || "")
        try {
            const jsonObj = value ? yaml.load(value) : {}
            if (!collection.processors.value)
                collection.processors.set([{
                    title: "default",
                    definition: ""
                }])
            collection.processors[0].definition.set(JSON.stringify(jsonObj) || '')
            setChanged(true)
        } catch {

        }
    }
    
    return (
        <div>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <CodeEditor language="yaml" value={yamlValue} onChange={onChangeValue} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    )
}

export default withTranslation()(ParsersPage)