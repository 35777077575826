import { Icon, Panel, ButtonToolbar, FlexboxGrid, IconButton, Button, Loader } from 'rsuite';
import Breadcrumb from './breadcrumb'
import React, { FC, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';

interface PageContentProps {
    title: React.ReactNode,
    toolbarLeft?: React.ReactFragment,
    toolbarRight?: React.ReactFragment,
    height?: string | number
}

const PageContent: FC<PageContentProps & WithTranslation> = ({ t, title, toolbarLeft, toolbarRight, height, children }) => {
    const [error, setError] = useState("")
    return (
        <div>
            <Panel header={<FlexboxGrid align="middle" justify="space-between">
                <FlexboxGrid.Item key="title">
                    <h3>{title}</h3>
                    <Breadcrumb />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item key="toolbar">
                    <FlexboxGrid align="bottom" justify="end">
                        <div className="field" style={{ height: 60 }}>                            
                            {/*<label>Tenant</label><KBSelector onChange={(value) => this.onChangeKb(value)} onError={error => this.setState({ error: error })} />*/}
                        </div>
                        {/*<IconButton disabled={this.KBsStore.state.isBusy || this.state.error != undefined || this.state.kbId == undefined} onClick={this.onGoToKB} icon={<Icon icon="gear" />} style={{ marginBottom: -3, border: '1px solid #e5e5ea' }} />
                        <IconButton disabled={this.KBsStore.state.isBusy || this.state.error != undefined} onClick={this.onNewKB} icon={<Icon icon="plus-square" />} style={{ marginBottom: -3, border: '1px solid #e5e5ea' }} />*/}
                    </FlexboxGrid>
                </FlexboxGrid.Item>
            </FlexboxGrid>}>
                {!error && children && <div>
                    {(toolbarLeft || toolbarRight) && <div className="page-toolbar">
                        {toolbarLeft && <ButtonToolbar className="inner-left">
                            {toolbarLeft}
                        </ButtonToolbar>}
                        {toolbarRight && <ButtonToolbar className="inner-right">
                            {toolbarRight}
                        </ButtonToolbar>}
                    </div>}
                    <div className="page-content" style={{ height: height }}>
                        {children}
                    </div>
                </div>}
                {/*this.KBsStore.state.isBusy && <div>
                    <FlexboxGrid justify="center" align="middle" style={{ height: 400 }}>
                        <FlexboxGridItem style={{ textAlign: 'center' }}>
                            <Loader content={t("Connecting...")} />
                        </FlexboxGridItem>
                    </FlexboxGrid>
                </div>*/}
                {error && <div>
                    <FlexboxGrid key="content" justify="center" align="middle" style={{ height: 400 }}>
                        <FlexboxGridItem style={{ textAlign: 'center' }}>
                            <Icon icon='plug' size='lg' /><br /><br />
                            <p>{t("Ups! It seems that you dont have connection to Internet")}</p>
                            <p><small>{error}</small></p>
                        </FlexboxGridItem>
                    </FlexboxGrid>
                </div>}
                {/*!this.KBsStore.state.isBusy && !this.state.error && !this.state.kbId && <div>
                    <FlexboxGrid justify="center" align="middle" style={{ height: 400 }}>
                        <FlexboxGridItem style={{ textAlign: 'center' }}>
                            <p>{t("There are no knowledge bases created. You should create a new one.")}</p><br />
                            <Button appearance="primary" onClick={this.onNewKB}>{t("Create your first Knowledge Base")}</Button>
                        </FlexboxGridItem>
                    </FlexboxGrid>
                </div>*/}
            </Panel>
        </div>
    );
}

export default withTranslation()(PageContent)