import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { clone, formatDate } from 'src/core/utils/object';
import { container } from 'src/inversify.config';
import { EntitySummary, EntitySummaryStore } from 'src/stores/entities-store';
import { Query } from 'src/core/stores/data-store';
import { Button, Icon, InputGroup, Message, Tooltip, Whisper, Uploader, Alert, Tag } from 'rsuite';
import { TableModel } from 'src/core/ui/table/table-model';
import { formatMessage } from 'src/core/services/http.service';
import Table from 'src/core/ui/table';
import RegionContainer from 'src/core/ui/frame/region-content';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import SearchBox from 'src/core/ui/form/search-box';
import { Link } from 'react-router-dom';
import { withIdentity, IdentityProps } from 'src/core/services/authentication';
import ContextMenu from 'src/core/ui/table/context-menu';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import showNewEntityDialog from './entity-new';

const EntitiesPage: FC<{ collectionId: string } & WithTranslation & IdentityProps> = ({ t, collectionId, identity }) => {

    const entitiesStore = container.get(EntitySummaryStore)
    entitiesStore.setCollection(collectionId)
    const entityState = entitiesStore.state
    const [query, setQuery] = useState({
        searchQuery: '',
        orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 15
    } as Query)

    const onChange = async (query: Query) => {
        entitiesStore.load(query)
        setQuery(query)
    }

    const onSearch = async (q?: string) => {
        let newQuery = clone(query || {}) as Query;
        newQuery.skip = 0;
        newQuery.searchQuery = q || "";
        await onChange(newQuery);
    }

    const onDeleteEntity = async (collectionId: string) => {
        await entitiesStore.delete(collectionId)
    }

    const onNewEntity = async () => {
        let result = await showNewEntityDialog(collectionId)
        await onChange(query)
    }

    useEffect(() => {
        entitiesStore.load(query)
    }, [query]);

    const tableModel = {
        query: query,
        loading: entityState.isBusy.value,
        columns: [
            {
                field: 'icon',
                width: 40,
                title: "",
                renderer: (item) => <Link to={`/collections/${collectionId}/entities/${item.id}`}><Icon icon="tag" /></Link>
            },
            {
                width: 150,
                field: 'title',
                title: t('Title'),
                renderer: (item) => <Link to={`/collections/${collectionId}/entities/${item.id || item.title}`}><span>{item.title}</span></Link>
            },
            {
                width: 150,
                field: 'entityType',
                title: t('Type'),
                renderer: (item) => <span>{item.entityType}</span>
            },
            {
                field: 'entityValuesSample',
                title: t('Samples'),
                flow: 1,
                renderer: (item) => <span>{item.entityValuesSample && item.entityValuesSample.map(o => (<Tag>{o}</Tag>))} <small>{item.entityValuesCount > 5 ? "("+t("and other {0} more").replace("{0}", `${item.entityValuesCount - 5}`)+")" : ""}</small></span>
            },
            {
                field: 'createdBy',
                title: t('Created by'),
                renderer: (item) => <span>{item.createdBy && item.createdBy.identityDisplayName}</span>
            },
            {
                field: 'createdOn',
                title: t('Created on'),
                renderer: (item) => <span>{formatDate(item.createdOn)}</span>
            },
            {
                field: 'modifiedBy',
                title: t('Modified by'),
                renderer: (item) => <span>{item.modifiedBy && item.modifiedBy.identityDisplayName}</span>
            },
            {
                field: 'modifiedOn',
                title: t('Modified on'),
                renderer: (item) => <span>{formatDate(item.modifiedOn)}</span>
            },
            {
                field: 'actions',
                width: 60,
                title: "",
                renderer: (item) => <ContextMenu
                    entries={[
                        //{
                        //    title: <div><Icon icon="download" /> {t("Download a copy")}</div>,
                        //    onSelect: () => {

                        //    }
                        //},
                        //{ divider: true },
                        {
                            title: <div><Icon icon="trash2" /> {t("Delete entity")}</div>, onSelect: () => {
                                ConfirmDialog({
                                    text: t("Are you sure want to delete the selected entity?"),
                                    type: "warning",
                                    okLabel: t("Yes"),
                                    cancelLabel: t("No"),
                                }).then(result => {
                                    if (result) {
                                        onDeleteEntity(item.id);
                                    }
                                });
                            }
                        },
                    ]}>
                    <Link to="#"><Icon icon="more" /></Link>
                </ContextMenu>
            },
        ],
        data: entityState,
        paginable: true
    } as TableModel<EntitySummary>;

    return (
        <RegionContainer
            toolbarLeft={
                [
                    <Whisper key='addEntity' placement="top" trigger="hover" speaker={<Tooltip>{t("New entity")}</Tooltip>}>
                        <Button placement="left" onClick={onNewEntity} appearance="primary">
                            <Icon icon="plus" />
                        </Button>
                    </Whisper>,
                    <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload list")}</Tooltip>}>
                        <Button placement="left" onClick={() => onChange(query)}>
                            <Icon icon="reload" />
                        </Button>
                    </Whisper>
                ]
            }
            toolbarRight={
                [
                    <FlexboxGridItem key="search">
                        <InputGroup inside>
                            <SearchBox key='searchBox' onSearch={onSearch} />
                        </InputGroup>
                    </FlexboxGridItem>
                ]
            }>
            {entityState.errorMessage.value && <Message type="error" description={formatMessage(entityState.errorMessage.value)} />}
            <Table model={tableModel} onChange={onChange} />
        </RegionContainer>
    )
}

export default withIdentity(withTranslation()(EntitiesPage))