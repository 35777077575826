import React, { FC } from 'react';
import { Table as RSTable } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TableModel } from './table-model';
import { Query } from 'src/core/stores/data-store';
import TablePagination from 'rsuite/lib/Table/TablePagination';
import { clone } from 'src/core/utils/object';

let Column = RSTable.Column;
let HeaderCell = RSTable.HeaderCell;
let Cell = RSTable.Cell;

interface TableProps<T> {
    model: TableModel<T>;
    onChange: (query: Query) => Promise<any>
}

const Table: FC<TableProps<any> & WithTranslation> = ({ model, onChange }) => {

    const onSortColumn = async (sortColumn: string, sortType: 'desc' | 'asc') => {
        var query = clone(model.query || {}) as Query;
        query.orderBy = [{
            direction: sortType == 'asc' ? 'Ascending' : 'Descending',
            field: sortColumn,
            useProfile: false
        }];
        try {
            await onChange(query);
        } finally {

        }
    }

    const onPageChange = async (pageNumber: number) => {
        var query = clone(model.query || {}) as Query;
        query.skip = (query.take || 10) * (pageNumber - 1);
        query.take = query.take || 10;
        try {
            await onChange(query);
        } finally {

        }
    }

    const onPageSizeChange = async (pageSize: number) => {
        var query = clone(model.query || {}) as Query;
        query.take = pageSize;
        try {
            await onChange(query);
        } finally {

        }
    }

    let sortColumn: string | undefined = undefined;
    let sortType: 'asc' | 'desc' = 'asc';

    if (model.query && model.query.orderBy && model.query.orderBy.length > 0) {
        sortColumn = model.query.orderBy[0].field;
        sortType = model.query.orderBy[0].direction == 'Ascending' ? 'asc' : 'desc';
    }

    let pageSize = model.paginable ? (model.query ? model.query.take : 10) : 5000;
    let currentPage = model.query ? Math.floor(model.query.skip / pageSize) + 1 : 1;
    let total = model.data ? model.data.count.value : 0;

    return (
        <div>
            <RSTable
                loading={model.loading}
                sortColumn={sortColumn}
                sortType={sortType}
                autoHeight={true}
                onSortColumn={onSortColumn}
                data={model.data ? model.data.items.map(o => o.value) || [] : []}
                onRowClick={data => {

                }}>
                {model.columns.map(col => <Column width={col.width} key={col.field} align={col.align} fixed={col.fixed} resizable={col.resizable} sortable={col.sortable} flexGrow={col.flow}>
                    <HeaderCell>{col.headerRenderer ? col.headerRenderer(col.title) : col.title}</HeaderCell>
                    {col.renderer ? <Cell>{col.renderer}</Cell> : <Cell dataKey={col.field} />}
                </Column>
                )}
            </RSTable>
            {model.paginable && <TablePagination
                lengthMenu={[
                    {
                        value: 10,
                        label: 10
                    },
                    {
                        value: 15,
                        label: 15
                    },
                    {
                        value: 20,
                        label: 20
                    },
                    {
                        value: 50,
                        label: 50
                    },
                    {
                        value: 500,
                        label: 500
                    }
                ]}
                activePage={currentPage}
                displayLength={pageSize}
                total={total}
                onChangePage={onPageChange}
                onChangeLength={onPageSizeChange}
            />}
        </div>
    )
}

export default withTranslation()(Table)