import { Panel, ButtonToolbar, FlexboxGrid } from 'rsuite';
import React, { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

interface PageContentProps {
    toolbarLeft?: React.ReactFragment,
    toolbarRight?: React.ReactFragment,
    height?: string | number
}

const RegionContent: FC<PageContentProps & WithTranslation> = ({ toolbarLeft, toolbarRight, height, children }) => {
    return (
        <div>
            <Panel>
                {children && <div>
                    <div className="page-toolbar">
                        <ButtonToolbar className="inner-left">
                            {toolbarLeft}
                        </ButtonToolbar>
                        <ButtonToolbar className="inner-right">
                            {toolbarRight}
                        </ButtonToolbar>
                    </div>
                    <div className="page-content" style={{ height: height }}>
                        {children}
                    </div>
                </div>}
            </Panel>
        </div>
    );
}

export default withTranslation()(RegionContent)