import 'reflect-metadata';
import * as React from 'react';
import { Container } from 'inversify';
import HttpService from 'src/core/services/http.service';
import { AppConfiguration, ConfigurationService } from "src/core/services/configuration.service";
import { PreferencesService } from "src/core/services/preferences.service";
import { CrawlerSummaryStore, CrawlerItemStore } from './stores/crawlers-store';
import { CrawlerPatchesSummaryStore, CrawlerPatchesItemStore } from './stores/crawlerPatches-store';
import { EntitySummaryStore, EntityItemStore } from './stores/entities-store';
import { CollectionItemStore, CollectionSummaryStore } from './stores/collections-store';
import { DocumentItemStore, DocumentSummaryStore } from './stores/documents-store';
import { SynonymsItemStore, SynonymsSummaryStore } from './stores/synonyms-store';
import { EndpointItemStore, EndpointSummaryStore } from './stores/endpoints-store';
import { BreadcrumbProvider } from 'src/core/stores/breadcrumb-store';

// Initialize DI/IoC container
const container = new Container();

const AppConfig = React.createContext<AppConfiguration>({} as any);

function initialize(config?: any, useMock?: boolean) {
    let baseUri = `${config.serviceUrl}`;
    if (!container.isBound(HttpService)) {
        // Initialize services if container is not configured before
        container.bind(HttpService).toSelf().inSingletonScope().onActivation((context: any, instance: any) => {
            instance.setup(baseUri);
            return instance;
        });
    }

    container.bind(ConfigurationService).toSelf().inSingletonScope().onActivation((context, instance) => {
        var config = instance.current();
        return instance;
    });

    container.bind(PreferencesService).toSelf().inSingletonScope().onActivation((context, instance) => {
        var prefs = instance.current();
        return instance;
    });
    
    container.bind(BreadcrumbProvider).toConstantValue(new BreadcrumbProvider());
    container.bind(CollectionSummaryStore).toConstantValue(new CollectionSummaryStore(baseUri));
    container.bind(CollectionItemStore).toConstantValue(new CollectionItemStore(baseUri));
    container.bind(SynonymsSummaryStore).toConstantValue(new SynonymsSummaryStore(baseUri));
    container.bind(SynonymsItemStore).toConstantValue(new SynonymsItemStore(baseUri));
    container.bind(DocumentSummaryStore).toConstantValue(new DocumentSummaryStore(baseUri));
    container.bind(DocumentItemStore).toConstantValue(new DocumentItemStore(baseUri));
    container.bind(CrawlerSummaryStore).toConstantValue(new CrawlerSummaryStore(baseUri));
    container.bind(CrawlerItemStore).toConstantValue(new CrawlerItemStore(baseUri));
    container.bind(CrawlerPatchesSummaryStore).toConstantValue(new CrawlerPatchesSummaryStore(baseUri));
    container.bind(CrawlerPatchesItemStore).toConstantValue(new CrawlerPatchesItemStore(baseUri));
    container.bind(EntitySummaryStore).toConstantValue(new EntitySummaryStore(baseUri));
    container.bind(EntityItemStore).toConstantValue(new EntityItemStore(baseUri));
    container.bind(EndpointSummaryStore).toConstantValue(new EndpointSummaryStore(baseUri));
    container.bind(EndpointItemStore).toConstantValue(new EndpointItemStore(baseUri));
}

export { container, initialize, AppConfig };