import React, { FC } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import EndpointsListPage from './endpoint-list';
import EndpointsDetailPage from './endpoint-detail';

const EndpointsIndex: FC<RouteComponentProps<any>> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.url}`} component={EndpointsListPage} />
        <Route exact path={`${match.url}/:endpointId`} component={EndpointsDetailPage} />
    </Switch>
}

export default withRouter(EndpointsIndex)