import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';
import { IdentityReference } from './common-types'

export interface EndpointSummary {
  id: string
  title: string
  createdBy: IdentityReference
  createdOn: Date
  modifiedBy: IdentityReference
  modifiedOn: Date
}

export interface EndpointItem {
  id: string
  title: string
  apiKey: string
  blackList: string[]
  createdBy: IdentityReference
  createdOn: Date
  modifiedBy: IdentityReference
  modifiedOn: Date
}

export interface NewEndpointItem {
  title: string
}

export class EndpointSummaryStore extends DataStore<EndpointSummary> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/endpoints`, []);
  }
}

export class EndpointItemStore extends FormStore<EndpointItem, NewEndpointItem> {
  constructor(baseUrl: string) {
    super(`${baseUrl}/api/endpoints`);
  }
}