import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';
import { IdentityReference } from './common-types';


export interface CrawlerSummary {
    id: string
    title: string
    crawlerType: string
    status: string
    lastRun?: Date
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface CrawlerItem {
    id: string
    title: string
    schedule: string
    settings: string
    crawlerType: string
    status: string
    lastRun?: Date
    properties: { [key: string]: any }
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface NewCrawlerItem {
    title: string
    crawlerType: string
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export class CrawlerSummaryStore extends DataStore<CrawlerSummary> {
    private realBaseUrl: string

    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/crawlers`, []);
        this.realBaseUrl = baseUrl
    }
    public async setCollection(id: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${id}/crawlers`
    }
}

export class CrawlerItemStore extends FormStore<CrawlerItem, NewCrawlerItem> {
    private realBaseUrl: string
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/crawlers`);
        this.realBaseUrl = baseUrl
    }

    public async setCollection(collectionId: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${collectionId}/crawlers`
    }

    public async getLogs(crawlerId: string) {
        return await this.handleCallAsync(async () => {
            var response = await this.httpService.get<string>(`${this.baseUrl}/${encodeURIComponent(crawlerId)}/logs`);
            return response.data;
        })
    }

    public async runCrawler(crawlerId: string) {
        return await this.handleCallAsync(async () => {
            await this.httpService.post<any, any>(`${this.baseUrl}/${encodeURIComponent(crawlerId)}/run`, {});
            return true;
        })
    }
}