import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { container } from 'src/inversify.config';
import { CollectionItemStore } from 'src/stores/collections-store';
import { Button, ButtonToolbar, Icon, Loader, Message, Nav, Tooltip, Whisper } from 'rsuite';
import { formatMessage } from 'src/core/services/http.service';
import { BreadcrumbProvider } from 'src/core/stores/breadcrumb-store';
import PageContainer from 'src/core/ui/frame/page-content';
import DocumentsPage from './documents/document-list';
import ParsersPage from './parsers/parsers-detail';
import CrawlersPage from './crawlers/crawler-list';
import EntitiesPage from './entities/entity-list';

const CollectionsPage: FC<RouteComponentProps<{ collectionId: string }> & WithTranslation> = ({ t, match }) => {
    const breadcrumbProvider = container.get(BreadcrumbProvider)
    const collectionStore = container.get(CollectionItemStore)
    const collectionState = collectionStore.state
    const isBusy = collectionState.isBusy.value
    const errorMessage = collectionState.errorMessage.value
    const collectionId = match.params.collectionId
    const title = collectionState.item.value && collectionState.item.title.value || t("Collection")
    const [activeTab, setActiveTab] = useState("docs")
    const [changed, setChanged] = useState(false)

    const onSave = () => {
        collectionStore.save(collectionId, collectionState.item.value)
    }

    useEffect(() => {
        collectionStore.load(collectionId)
    }, [collectionId]);

    useEffect(() => {
        breadcrumbProvider.setTitle(match.url, title)
        breadcrumbProvider.setTitle(`${match.url}/documents`, "")
    }, [title]);
        
    return (
        <PageContainer title={<div><Icon icon="book" />&nbsp;<span>{title}</span></div>}>
            {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
            {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
            {!isBusy && <div>
                <Nav appearance="tabs" activeKey={activeTab} onSelect={value => setActiveTab(value) }>
                    <Nav.Item eventKey="docs"><Icon icon="file-text-o" /> {t("Documents")}</Nav.Item>
                    <Nav.Item eventKey="entities"><Icon icon="tag" /> {t("Entities")}</Nav.Item>
                    <Nav.Item eventKey="parsers"><Icon icon="user-analysis" /> {t("Parsers")}</Nav.Item>
                    <Nav.Item eventKey="crawlers"><Icon icon="flow" /> {t("Crawlers")}</Nav.Item>
                </Nav>
            </div>}
            {activeTab == 'docs' && <DocumentsPage collectionId={collectionId} />}
            {activeTab == 'entities' && <div>
                <EntitiesPage collectionId={collectionState.item.id.value} /> 
            </div>}
            {activeTab == 'parsers' && <div>
                <ButtonToolbar style={{ textAlign: 'right', paddingTop: 10, paddingBottom: 10 }}>
                    <Whisper key='save' placement="top" trigger="hover" speaker={<Tooltip>{t("Save")}</Tooltip>}>
                        <Button disabled={!changed} placement="left" appearance="primary" onClick={() => onSave()}>
                            <Icon icon="save" /> {t("Save")}
                        </Button>
                    </Whisper>
                </ButtonToolbar>
                <ParsersPage collection={collectionState.item} changed={changed} setChanged={setChanged} />
            </div>}
            {activeTab == 'crawlers' && <div>
                <CrawlersPage collectionId={collectionState.item.id.value} />                
            </div>}
        </PageContainer>
    )
}

export default withTranslation()(withRouter(CollectionsPage))