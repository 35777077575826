import React, { FC, useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { container } from 'src/inversify.config';
import { AttachmentItem, DocumentItemStore, Entity } from 'src/stores/documents-store';
import { Alert, Button, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Icon, Input, List, Loader, Message, Tag, TagPicker, Toggle, Tooltip, Whisper, Uploader, Dropdown, Popover, Row, Col } from 'rsuite';
import { formatMessage } from 'src/core/services/http.service';
import PageContainer from 'src/core/ui/frame/page-content';
import MarkdownEditor from 'src/core/ui/form/markdown-editor';
import DialogEditor from 'src/components/dialog-editor';
import { formatDateTime } from 'src/core/utils/object';
import { IGroup } from 'src/core/utils/linq';
import ContentDialog from 'src/core/ui/dialogs/content-dialog';
import { IntentEditor } from 'src/core/ui/intents/intent-editor';
import { LanguagePicker } from 'src/core/ui/language/language-picker';
import { none } from '@hookstate/core';
import { EntitySummaryStore, EntityItemStore } from 'src/stores/entities-store';
import { useWindowSize } from 'src/core/utils/hooks';
import { IdentityProps, withIdentity } from 'src/core/services/authentication';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import yaml from 'js-yaml';

const DocumentsPage: FC<RouteComponentProps<{ collectionId: string, documentId: string }> & WithTranslation & IdentityProps> = ({ t, match, identity }) => {
  const windowSize = useWindowSize();

  const documentStore = container.get(DocumentItemStore)
  const collectionId = match.params.collectionId
  documentStore.setCollection(collectionId)
  const documentState = documentStore.state

  const entitiesStore = container.get(EntitySummaryStore)
  entitiesStore.setCollection(collectionId)
  const entitiesState = entitiesStore.state

  const entityStore = container.get(EntityItemStore)
  entityStore.setCollection(collectionId)
  const entityState = entityStore.state

  const errorMessage = documentState.errorMessage.value
  const documentId = match.params.documentId
  const title = documentState.item.value && documentState.item.title.value || t("Document")
  const [entities, setEntities] = useState<IGroup<string, Entity>[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const isBusy = documentState.isBusy.value;
  const [yamlBody, setYamlBody] = useState<string>("")

  const onReload = async () => {
    await documentStore.load(documentId)
    if (documentState.item.entities.value) {
      setEntities(documentState.item.entities.value.groupBy(o => o.type));
    }
    if (documentState.item.contentType.value === 'dialog') {
      const jsonValue = documentState.item.body.value ? yaml.dump(JSON.parse(documentState.item.body.value)) : '';
      setYamlBody(jsonValue);
    }
  }

  const onSave = async () => {
    if (documentState.item.contentType.value === 'dialog') {
      documentState.item.body.set(JSON.stringify(yamlBody ? yaml.load(yamlBody) : {}))
    }
    if (!isNaN(documentState.item.boost.value)) {
      documentState.item.boost.set(1 * documentState.item.boost.value);
    } else {
      documentState.item.boost.set(1);
    }
    await documentStore.save(documentId, documentState.item.value);
    await onReload();
  }

  useEffect(() => {
    onReload()
  }, [collectionId, documentId]);

  const getEntityTagName = (type: string) => {
    switch (type) {
      case "NUMBER":
        return <Tag color="violet" style={{ marginTop: 10 }}>{t("Duration")}</Tag>
      case "DURATION":
        return <Tag color="violet" style={{ marginTop: 10 }}>{t("Number")}</Tag>
      case "AMOUNT-OF-MONEY":
        return <Tag color="violet" style={{ marginTop: 10 }}>{t("Money")}</Tag>
      case "TEMPERATURE":
        return <Tag color="violet" style={{ marginTop: 10 }}>{t("Temperature")}</Tag>
      case "URL":
        return <Tag color="violet" style={{ marginTop: 10 }}>{t("Url")}</Tag>
      case "PER":
        return <Tag color="green" style={{ marginTop: 10 }}>{t("Person")}</Tag>
      case "ORG":
        return <Tag color="orange" style={{ marginTop: 10 }}>{t("Organization")}</Tag>
      case "LOC":
        return <Tag color="blue" style={{ marginTop: 10 }}>{t("Localization")}</Tag>
      case "MISC":
        return <Tag color="violet" style={{ marginTop: 10 }}>{t("Miscellaneous")}</Tag>
      case "PHONE-NUMBER":
        return <Tag color="violet" style={{ marginTop: 10 }}>{t("Phone number")}</Tag>
      case "DATE":
      case "TIME":
        return <Tag color="cyan" style={{ marginTop: 10 }}>{t("Date and time")}</Tag>
      default:
        return <Tag color="black" style={{ marginTop: 10 }}>{type}</Tag>
    }
  }

  const getEntityString = (entity: Entity) => {
    let parts: string[] = []
    if (entity.text)
      parts.push(entity.text)
    if (entity.value && (entity.value != entity.text || entity.value2)) {
      if (entity.value)
        parts.push(entity.text ? `(${entity.value})` : entity.value)
    }
    if (entity.unit) {
      parts.push(entity.unit)
    }
    return parts.join(" ");
  }

  const getDocumentStatus = () => {
    if (!documentState.item.value)
      return <div>{t("Loading...")}</div>
    switch (documentState.item.status.value) {
      case 'Published':
        return <Tag color="green">{t("Published")}</Tag>
      case 'Error':
        return <Tag color="red">{t("Error")}</Tag>
      case 'Pending':
        return <Tag color="yellow">{t("Pending")}</Tag>
      case 'Modified':
        return <Tag color="yellow">{t("Modified")}</Tag>
      default:
        return <Tag>{t(documentState.item.status.value)}</Tag>
    }
  }

  const onProcess = async () => {
    try {
      await documentStore.processDocument(documentId);
      Alert.info(t('The operation was enqueued successfully. Check the logs later to see the result.'))
    } catch {
      Alert.info(t('An error occured while processing the request.'))
    }
  }

  const onShowLog = async () => {
    const result = await documentStore.getLogs(documentId);
    ContentDialog({
      title: t("Processor log"),
      okLabel: t("Ok"),
      text: <pre style={{ color: 'gray', backgroundColor: 'black', padding: '10px', maxWidth: '700px', width: '100%', height: '600px', overflow: 'auto' }}>{result}</pre>
    })
  }

  const upsertEntity = async (entity: string, values: string[]) => {
    await entitiesStore.pathEntity({
      title: entity.replace(/^@/g, ''),
      entityValues: values.map(o => ({ language: documentState.item.language.value, value: o }))
    });
  };

  const getEntities = async (prefix: string, take?: number) => {
    if (prefix)
      prefix = prefix.replace(/^@/g, '');
    await entitiesStore.load({ searchQuery: prefix, take: take || 5 })
    return (entitiesState.items && entitiesState.items.map(o => `@${o.title.value}`)) || [];
  };

  const getEntityValues = async (entity: string) => {
    await entityStore.load(entity)
    return entityState.item && entityState.item.value && entityState.item.entityValues.value ? entityState.item.entityValues.value.map(o => o.value) : []
  };

  const onAttachmentUploaded = (response: any) => {
    if (response.item) {
      response.item.forEach((item: AttachmentItem) => {
        const attachmentResult = attachments.filter(o => o.fileName.value == item.fileName);
        const attachment = { fileName: item.fileName, contentType: item.contentType, contentUri: item.contentUri, content: item.content };
        if (attachmentResult.length > 0) {
          attachmentResult[0].set(attachment);
        } else {
          if (attachments.value) {
            attachments[attachments.length].set(attachment);
          } else {
            attachments.set([attachment])
          }
        }
      })
    }
  }

  const onDeleteAttachment = async (filename: string) => {
    ConfirmDialog({
      text: t("Are you sure want to delete the attachment named '{0}'? This operation cannot be reverted").replace("{0}", filename),
      type: "warning",
      okLabel: t("Yes"),
      cancelLabel: t("No"),
    }).then(async result => {
      if (result) {
        await documentStore.removeAttachment(documentId, filename ?? "");
        const attachmentResult = attachments.filter(o => o.fileName.value == filename);
        if (attachmentResult.length > 0)
          attachmentResult[0].set(none);
      }
    });
  }

  const onDownloadAttachment = async (filename: string) => {
    const sUrl = `/api/collections/${collectionId}/documents/${documentId}/attachments/${filename}?access_token=${identity.accessToken}`;
    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

    //If in Chrome or Safari - download via virtual link click
    if (isChrome || isSafari) {
      //Creating new link node.
      var link = document.createElement('a');
      link.href = sUrl;

      if (link.download !== undefined) {
        //Set HTML5 download attribute. This will prevent file from opening if supported.
        var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
        link.download = fileName;
      }

      //Dispatching click event.
      if (document.createEvent) {
        var e = document.createEvent('MouseEvents');
        e.initEvent('click', true, true);
        link.dispatchEvent(e);
        return true;
      }
    }

    // Force file download (whether supported by server).
    var query = '?download';

    window.open(sUrl + query);
  }

  const onCopyAttachmentUri = async (filename: string) => {
    const uri = await documentStore.getDocumentAttachmentLink(documentId, filename);
    if (uri) {
      const el = document.createElement('textarea');
      el.value = uri;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      Alert.info(t("Uri copied to clipboard successfully"));
    }
  }

  const ref = useRef();

  const audiences = documentState.item.value && documentState.item.audience.value ? documentState.item.audience.value.map(o => ({ label: o, value: o })) : [];
  const intents = documentState.item.value && documentState.item.intents;
  const questions = documentState.item.value && documentState.item.question.value || [];
  const attachments = documentState.item.value && documentState.item.attachments || [];

  return (
    <PageContainer
      title={<div><Icon icon="file-o" />&nbsp;<span>{title}</span></div>}
      toolbarLeft={
        [
          <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload this document")}</Tooltip>}>
            <Button placement="left" onClick={() => onReload()}>
              <Icon icon="reload" />
            </Button>
          </Whisper>,
          <Whisper key='status' placement="top" trigger="hover" speaker={<Tooltip>{t("Document processing status")}</Tooltip>}>
            <Button appearance='ghost' onClick={onShowLog} style={{ padding: '5px', border: 'solid 1px #f3f3f3' }}>
              {getDocumentStatus()}
            </Button>
          </Whisper>
        ]
      }
      toolbarRight={
        [
          <Whisper key='process' placement="top" trigger="hover" speaker={<Tooltip>{t("Process")}</Tooltip>}>
            <Button placement="left" onClick={() => onProcess()}>
              <Icon icon="play-circle" />
            </Button>
          </Whisper>,
          <Whisper key='show-log' placement="top" trigger="hover" speaker={<Tooltip>{t("Show processing log")}</Tooltip>}>
            <Button placement="left" onClick={() => onShowLog()}>
              <Icon icon="logo-dmp" />
            </Button>
          </Whisper>,
          <Whisper key='save' placement="top" trigger="hover" speaker={<Tooltip>{t("Save this document")}</Tooltip>}>
            <Button placement="left" appearance="primary" onClick={() => onSave()}>
              <Icon icon="save" /> {t("Save")}
            </Button>
          </Whisper>
        ]
      }>
      {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
      {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
      {!isBusy && documentState.item.value && <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'stretch' }}>
        <div>
          {documentState.item.contentType.value !== 'dialog' && <MarkdownEditor value={documentState.item.body.value} onChange={value => documentState.item.body.set(value || '')} height={800} width={(windowSize.width || 600) * 0.6} />}
          {documentState.item.contentType.value === 'dialog' && <DialogEditor value={yamlBody} onChange={value => setYamlBody(value || '')} height={800} width={(windowSize.width || 600) * 0.6} />}
        </div>
        <div style={{ flexGrow: 1 }}>
          <Form fluid className="fluid-tooltip details-panel" style={{ paddingLeft: 10 }}>
            <Row>
              <Col xs={20}>
                {<FormGroup>
                  <ControlLabel>{t("Title")}</ControlLabel>
                  <FormControl name="title" value={documentState.item.title.value} onChange={value => documentState.item.title.set(value)} />
                  <HelpBlock tooltip>{t("The title is the most important part of your document since will weight the most in the search engine. The boost multiplies the document score by this number. 1 is neutral. Greater than 1 will increase the rank and between 0 to 1 will decrease the document rank in the search result")}</HelpBlock>
                </FormGroup>}
              </Col>
              <Col xs={4}>
                {<FormGroup>
                  <ControlLabel>{t("Boost")}</ControlLabel>
                  <FormControl name="boost" value={documentState.item.boost.value || 1} onChange={value => documentState.item.boost.set(value)} />
                </FormGroup>}
              </Col>
            </Row>
            {<FormGroup>
              <Toggle checked={documentState.item.dontAdvertise.value} onChange={checked => { documentState.item.dontAdvertise.set(checked) }} /> <span>{t("Don't advertise")}</span>
              <HelpBlock style={{ marginTop: 1 }} tooltip>{t("If enabled, this document will not appear in suggestions")}</HelpBlock>
            </FormGroup>}
            {<FormGroup>
              <ControlLabel>{t("Audiences")}</ControlLabel>
              <TagPicker name="audience" creatable data={audiences} value={documentState.item.audience.value} onChange={value => documentState.item.audience.set(value)} placeholder={t("Select or create the document audience...")} />
              <HelpBlock tooltip>{t("You can segment visibility using audiences. If set, this document will be seen only by users that matches at least one audience.")}</HelpBlock>
            </FormGroup>}
            <FormGroup>
              <ControlLabel>{t("Language")}</ControlLabel>
              <LanguagePicker value={documentState.item.language.value} multi={documentState.item.contentType.value === 'dialog'} onChange={value => documentState.item.language.set(value as string)} style={{ width: '100%' }} />
              <HelpBlock tooltip>{t("Language is very important since AI algorithms are usually language specific.")}</HelpBlock>
            </FormGroup>
            <FormGroup>
              <ControlLabel>{documentState.item.contentType.value === 'dialog' ? t("Bootstrap intents") : t("Intents")}</ControlLabel>
              {<List hover bordered>
                {documentState.item.language.value && intents.value && intents.map((intent, index) => (
                  <List.Item key={index} index={index} style={{ padding: 0 }}>
                    <div style={{ display: 'flex' }}>
                      <IntentEditor style={{ flexGrow: 1, border: 0 }} placeholder={t("Write your intent sample")} value={intent.text.value} onChange={value => intent.text.set(value)} getEntities={getEntities} getEntityValues={getEntityValues} upsertEntity={upsertEntity} />
                      <Whisper placement='left' speaker={<Tooltip>{t("Remove intent")}</Tooltip>}>
                        <Button style={{ flexGrow: 0 }} onClick={() => intent.set(none)}><Icon icon='trash' /></Button>
                      </Whisper>
                    </div>
                  </List.Item>
                ))}
                {!documentState.item.language.value && <Message type='info' description={t("Please, select a language first or train the document in order to add new intents")} />}
              </List>}
              {documentState.item.language.value && <div style={{ textAlign: 'right' }}>
                <Button style={{ marginTop: 5 }} onClick={() => {
                  if (intents.value) {
                    intents[intents.length].set({ suggest: false, text: "" });
                  } else {
                    intents.set([{ suggest: false, text: "" }])
                  }

                }}>
                  <Icon icon='plus' /> {t("Add new intent")}
                </Button>
              </div>}
            </FormGroup>
            {<FormGroup>
              <ControlLabel>{t("Summary")}</ControlLabel>
              <Input componentClass="textarea" rows={6} name="summary" creatable data={[]} value={documentState.item.summary.value} onChange={value => documentState.item.summary.set(value)} placeholder={t("Summary...")} />
            </FormGroup>}
            <FormGroup>
              <ControlLabel>{t("Attachments")}</ControlLabel>
              <List hover>
                {attachments.value && attachments.map((attachment, index) => (
                  <List.Item key={index} index={index} style={{ padding: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ flexGrow: 1, border: 0, padding: 5 }}><Icon icon='file-o' /> {attachment.fileName.value ?? t("(No filename)")}</span>
                      <Whisper placement="bottomEnd"
                        speaker={<Popover ref={ref} full>
                          <Dropdown.Menu onSelect={(eventKey: string) => {
                            switch (eventKey) {
                              case 'copy':
                                onCopyAttachmentUri(attachment.fileName.value)
                                break;
                              case 'download':
                                onDownloadAttachment(attachment.fileName.value)
                                break;
                              case 'delete':
                                onDeleteAttachment(attachment.fileName.value)
                                break;
                            }
                          }}>
                            <Dropdown.Item eventKey="copy"><Icon icon='copy-o' /> {t("Copy uri")}</Dropdown.Item>
                            <Dropdown.Item eventKey="download"><Icon icon='download' /> {t("Download attachment")}</Dropdown.Item>
                            <Dropdown.Item eventKey="delete"><Icon icon='trash' /> {t("Remove attachment")}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Popover>}
                        trigger="click">
                        <Button appearance='subtle'>
                          <Icon icon='ellipsis-v' />
                        </Button>
                      </Whisper>
                    </div>
                  </List.Item>
                ))}
              </List>
              {isUploading && <Loader center backdrop size="md" content={t("Loading...")} />}
              <div style={{ textAlign: 'right' }}>
                <Whisper key='uploadDocument' placement="top" trigger="hover" speaker={<Tooltip>{t("Upload document")}</Tooltip>}>
                  <Uploader
                    disabledFileItem
                    fileListVisible={false}
                    multiple={false}
                    withCredentials
                    onProgress={() => {
                      setIsUploading(true)
                    }}
                    action={`/api/collections/${collectionId}/documents/${documentId}/attachments?access_token=${identity && identity.accessToken}`}
                    style={{ display: 'inline-block' }}
                    onSuccess={(response, file) => {
                      Alert.info(t('The attachment was upload successfully.'));
                      onAttachmentUploaded(response);
                      setIsUploading(false);
                    }}
                    onError={() => {
                      Alert.error(t('An error has occurred while uploading the attachment.'));
                      setIsUploading(false);
                    }}>
                    <Button style={{ marginTop: 5 }} placement="right" appearance="primary">
                      <Icon icon='upload' /> {t("Upload attachment")}
                    </Button>
                  </Uploader>
                </Whisper>
              </div>
            </FormGroup>
            {questions && questions.values.length > 0 && <FormGroup>
              <ControlLabel>{t("Questions")}</ControlLabel>
              <List hover bordered>
                {questions.map((question, index) => (
                  <List.Item key={index} index={index}>
                    <span>{question}</span>
                  </List.Item>
                ))}
              </List>
            </FormGroup>}
            {entities && entities.values.length > 0 && <FormGroup>
              <ControlLabel>{t("Entities")}</ControlLabel>
              {entities.map(entityGroup => (
                <div>
                  {getEntityTagName(entityGroup.key)}
                  <List hover bordered>
                    {entityGroup.items.map((entity, index) => (
                      <List.Item key={index} index={index}>
                        {getEntityString(entity)}
                      </List.Item>
                    ))}
                  </List>
                </div>
              ))}
            </FormGroup>}
            {documentState.item.createdOn.value && <FormGroup>
              <ControlLabel>{t("Created on")}</ControlLabel>
              <FormControl name="createdOn" readOnly value={formatDateTime(documentState.item.createdOn.value)} />
            </FormGroup>}
            {documentState.item.createdBy.value && <FormGroup>
              <ControlLabel>{t("Created by")}</ControlLabel>
              <FormControl name="createdBy" readOnly value={`${documentState.item.createdBy.value.identityDisplayName} (${documentState.item.createdBy.value.identityId})`} />
            </FormGroup>}
            {documentState.item.modifiedOn.value && <FormGroup>
              <ControlLabel>{t("Modified on")}</ControlLabel>
              <FormControl name="modifiedOn" readOnly value={formatDateTime(documentState.item.modifiedOn.value)} />
            </FormGroup>}
            {documentState.item.modifiedBy.value && <FormGroup>
              <ControlLabel>{t("Modified by")}</ControlLabel>
              <FormControl name="modifiedBy" readOnly value={`${documentState.item.modifiedBy.value.identityDisplayName} (${documentState.item.modifiedBy.value.identityId})`} />
            </FormGroup>}
          </Form>
        </div>
      </div>}
    </PageContainer>
  )
}

export default withIdentity(withTranslation()(withRouter(DocumentsPage)))