import React, { FC } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import InsightsHomePage from './home';

const InsightsIndex: FC<RouteComponentProps<any>> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.url}`} component={InsightsHomePage} />
    </Switch>
}

export default withRouter(InsightsIndex)