import React, { FC } from 'react';
import { Button, Panel, Icon } from 'rsuite';
import { useHistory } from "react-router-dom";
import './hero-button.css'

export interface HeroButtonProps {
    icon: string,
    title: string,
    description: string,
    href: string,
    width?: number,
    height?: number
}

const HeroButton: FC<HeroButtonProps> = ({ icon, title, description, href, width, height }) => {
    let history = useHistory();

    return (
        <Button appearance='ghost' className='hero-button' onClick={() => history.push(href)} style={{ marginRight: 10, width: width || 'auto', height: height || 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left', width: width || 'auto' }}>
                <div>
                    <Icon icon={icon as any} size='3x' style={{ margin: 10, marginRight: 20 }} />
                </div>
                <div>
                    <Panel header={title}>
                        <p style={{ width: width ? width - 100 : 'auto', wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                            <small>{description}</small>
                        </p>
                    </Panel>
                </div>
            </div>
        </Button>
    );
}

export default HeroButton;