import React, { FC, useContext, useEffect, useState } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import HomePage from './home';
import classNames from 'classnames';
import SecureContent from 'src/core/services/authentication';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppConfig } from 'src/inversify.config';
import { Link } from 'react-router-dom';
import LoginPage from './login';

import {
    Container,
    Sidebar,
    Sidenav,
    Icon,
    Content,
    Nav,
    DOMHelper
} from 'rsuite';
import NavToggle from 'src/core/ui/frame/nav-toggle';
import logo from 'src/assets/images/logo.png';
import InsightsPage from './insights';
import CollectionsPage from './collections';
import SynonymsPage from './synonyms';
import SearchPage from './search';
import { authenticationService } from 'src/core/services/authentication'

const { getHeight, on } = DOMHelper;

const sidebarAppearance: string | undefined = undefined; 

const HomeIndex: FC<RouteComponentProps<any> & WithTranslation> = ({ match, t }) => {
    const config = useContext(AppConfig) as any;
    const [expand, setExpand] = useState(localStorage.getItem('expand') == "true")
    const [windowHeight, setWindowHeight] = useState(100)

    useEffect(() => {
        const resizeListenner = on(window, 'resize', ()=>setWindowHeight(getHeight(window)))
        return function cleanup() {
            if (resizeListenner) {
                resizeListenner.off();
            }
        };
    }, [])

    if (!authenticationService.currentTokenIsValid()) {
        return <LoginPage />
    }

    const handleToggle = () => {
        const currentValue = !expand;
        setExpand(currentValue);
        localStorage.setItem('expand', currentValue ? "true" : "false");
    };

    let navBodyStyle = {};
    if (expand) {
        navBodyStyle = {
            height: windowHeight - 112,
            overflow: 'auto'
        };
    }

    const containerClasses = classNames('page-container', {
        'container-full': !expand
    });

    return <SecureContent
        config={{
            authority: config!.identityServerUrl,
            redirectUri: `${config!.serviceUrl}/portal`,
            resource: config!.scopes,
            clientId: config!.clientId,
            passthrough: config!.passthrough
        }}>

        <Container className="frame">
            <Sidebar
                appearance={sidebarAppearance}
                style={{ display: 'flex', flexDirection: 'column' }}
                width={expand ? 260 : 56}
                collapsible>
                <Sidenav.Header>
                    <div className="header-hrand">
                        <Link to="/">
                            <img src={logo} height={30} style={{ verticalAlign: 0, marginTop: -5, marginLeft: -5, float: 'left' }} />
                            <span style={{ marginLeft: 12 }}> {t('KNOWLEDGE MANAGER')}</span>
                        </Link>
                    </div>
                </Sidenav.Header>
                <Sidenav appearance={sidebarAppearance} expanded={expand} defaultOpenKeys={['3']} activeKey={[]}>
                    <Sidenav.Body style={navBodyStyle}>
                        <Nav>
                            <Nav.Item
                                componentClass={Link}
                                to={`/collections`}
                                icon={<Icon icon="book" />}>
                                {t("Collections")}
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <Nav.Item
                                componentClass={Link}
                                to={`/synonyms`}
                                icon={<Icon icon="language" />}>
                                {t("Synonyms")}
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <Nav.Item
                                componentClass={Link}
                                to={`/endpoints`}
                                icon={<Icon icon="search" />}>
                                {t("Search endpoints")}
                            </Nav.Item>
                        </Nav>
                        {/*<Nav>
                            <Nav.Item
                                componentClass={Link}
                                to={`/synonyms`}
                                icon={<Icon icon="language" />}>
                                {t("Synonyms")}
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <Nav.Item
                                componentClass={Link}
                                to={`/skills`}
                                icon={<Icon icon="creative" />}>
                                {t("Skills")}
                            </Nav.Item>
                        </Nav>
                        <Nav>
                            <Nav.Item
                                componentClass={Link}
                                to={`/chatbots`}
                                icon={<Icon icon="comments-o" />}>
                                {t("Chatbots")}
                            </Nav.Item>
                        </Nav>                       
                        <Nav>
                            <Nav.Item
                                componentClass={Link}
                                to={`/insights`}
                                icon={<Icon icon="logo-analytics" />}>
                                {t("Insights")}
                            </Nav.Item>
                        </Nav>*/} 
                    </Sidenav.Body>
                </Sidenav>
                {<NavToggle expand={expand} onChange={handleToggle} />}
            </Sidebar>

            <Container className={containerClasses}>
                <Content>
                    <Route exact path={`${match.url}`} component={HomePage} />
                    <Route path={`${match.url}collections`} component={CollectionsPage} />
                    <Route path={`${match.url}synonyms`} component={SynonymsPage} />
                    <Route path={`${match.url}insights`} component={InsightsPage} />
                    <Route path={`${match.url}endpoints`} component={SearchPage} />
                </Content>
            </Container>
        </Container> 
    </SecureContent>
}

export default withTranslation()(withRouter(HomeIndex))