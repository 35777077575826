import React from 'react';
import { Icon } from 'rsuite';
import ReactDOM from 'react-dom';
import { ContentDialogModal } from './content-dialog';

class ConfirmDialogModal extends React.Component<{ onClose: (result: boolean) => void, type: 'warning' | 'info' | 'error' | 'success', okLabel?: string, cancelLabel?: string }> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let type = this.props.type;
        let iconType = 'warning';
        let iconColor = '#ffb300';
        switch (type) {
            case 'warning':
                iconType = 'exclamation-triangle';
                iconColor = '#ffb300';
                break;

            case 'info':
                iconType = 'exclamation-circle';
                iconColor = '#007bff';
                break;

            case 'error':
                iconType = 'warning';
                iconColor = '#dc3545';
                break;

            case 'success':
                iconType = 'ok-circle';
                iconColor = '#28a745';
                break;
        }
        return <ContentDialogModal title={undefined} cancelLabel={this.props.cancelLabel} okLabel={this.props.okLabel} onClose={this.props.onClose}>
            <Icon
                icon={iconType as any}
                style={{
                    float: 'left',
                    marginRight: 20,
                    color: iconColor,
                    fontSize: 24
                }}
            />
            {this.props.children}
        </ContentDialogModal>
    }
}

export interface ConfirmDialogOptions {
    text: string | React.ReactNode,
    type?: 'warning' | 'info' | 'error' | 'success',
    okLabel?: string, cancelLabel?: string
}

function ConfirmDialog(options: ConfirmDialogOptions): Promise<boolean> {
    return new Promise((response, reject) => {
        let div = document.createElement('div');
        let modalRoot = document.getElementById("modal-root");
        ReactDOM.render(<ConfirmDialogModal type={options.type || 'info'} okLabel={options.okLabel} cancelLabel={options.cancelLabel} onClose={(result: boolean) => {
            response(result);
            ReactDOM.unmountComponentAtNode(div);
            modalRoot!.removeChild(div);
        }}>{options.text}</ConfirmDialogModal>, div);
        modalRoot!.appendChild(div);
    });
};

export default ConfirmDialog;