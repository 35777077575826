import React, { useMemo } from 'react'
import { TagPicker } from 'rsuite';
import ISO6391 from 'iso-639-1';

export interface LanguagePickerProps {
    /** Idioma selecionado en formato ISO 639-1 (dos letras) */
    value?: string[]
    /** Evento que se lanza al seleccionar un idioma */
    onChange?: (value: string[]) => void
    /** Lista de idiomas disponibles (es, en, pt, ...) */
    availableLanguages?: string[],
    style?: React.CSSProperties
}

export const LanguagesPicker: React.FC<LanguagePickerProps> = ({ value, onChange, availableLanguages, ...props }) => {
    if (!availableLanguages)
        availableLanguages = ['en', 'es', 'pt']

    const getLanguageName = (lang: string) => {
        if (!lang) return lang;
        return ISO6391.getName(lang);
    }

    const data = useMemo(() => availableLanguages!.map(o => ({value: o, label: getLanguageName(o)})), [availableLanguages])

    return (
        <TagPicker name="language" value={value || []} onChange={val => { if (onChange) { onChange(val) } }} data={data} style={{ width: '100%' }} />                            
    )
}