import React from 'react'
import './region.css'

export interface RegionProps {
    children: JSX.Element,
}

export const Region: React.FC<RegionProps> = ({ children }) => {
    return (
        <div className='region'>
            {children}
        </div>
    )
}