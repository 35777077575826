import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';
import { IdentityReference } from './common-types'

export interface CollectionSummary {
    id: string
    title: string
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface ProcessorItem {
    title: string
    definition: string
}

export interface CollectionItem {
    id: string
    title: string
    processors: ProcessorItem[]
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface NewCollectionItem {
    title: string
}

export class CollectionSummaryStore extends DataStore<CollectionSummary> {
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections`, []);
    }
}

export class CollectionItemStore extends FormStore<CollectionItem, NewCollectionItem> {
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections`);
    }

    public async processDocument(collectionId: string, documentId: string) {
        return await this.handleCallAsync(async () => {
            var response = await this.httpService.post<any, any>(`${this.baseUrl}/${collectionId}/documents/${documentId}/process`, {});
            return true;
        })         
    }
}