import { Navbar, Nav, Icon } from 'rsuite';
import { Link, RouterProps, withRouter } from 'react-router-dom';
import React, { FC } from 'react';
import { IdentityProps, withIdentity } from '../../services/authentication';

interface NavToggleProps {
    expand?: boolean,
    onChange?: () => void
}

const styles = {
    icon: {
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center'
    } as any,
    navItem: {
        width: 56,
        textAlign: 'center'
    } as any
};

const NavToggle: FC<NavToggleProps & IdentityProps & RouterProps> = ({ expand, onChange, identity, history }) => {

    return (
        <Navbar className="nav-toggle">
            <Navbar.Body>
                <Nav>
                    <Nav.Item
                        componentClass={Link}
                        onClick={() => window.location.href = `/hangfire?access_token=${identity.accessToken}`}
                        icon={<Icon style={{ paddingLeft: 5 }} icon="cog" />}>
                    </Nav.Item>
                </Nav>
                <Nav pullRight>
                    <Nav.Item onClick={onChange} style={styles.navItem}>
                        <Icon icon={expand ? 'angle-left' : 'angle-right'} />
                    </Nav.Item>
                </Nav>
            </Navbar.Body>
        </Navbar>
    );
}

export default withRouter(withIdentity(NavToggle))