import React, { Component } from 'react';
import { Modal, Button } from 'rsuite';
import ReactDOM from 'react-dom';

export interface ContentDialogOptions {
    title?: string | React.ReactNode,
    text: string | React.ReactNode,
    okLabel?: string,
    cancelLabel?: string,
    onlyOk?: boolean
}

class ContentDialogModal extends React.Component<{ onClose: (result: boolean) => void, title?: string | React.ReactNode, okLabel?: string, cancelLabel?: string, onlyOk?: boolean }> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Modal backdrop="static" show onHide={() => this.props.onClose(false)}>
                {this.props.title && <Modal.Header>
                    {this.props.title}
                </Modal.Header>}
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.onClose(true)} appearance="primary">
                        {this.props.okLabel || "Yes"}
                    </Button>
                    {!this.props.onlyOk && <Button onClick={() => this.props.onClose(false)} appearance="subtle">
                        {this.props.cancelLabel || "No"}
                    </Button>}
                </Modal.Footer>
            </Modal>
        );
    }
}

function ContentDialog(options: ContentDialogOptions): Promise<boolean> {
    return new Promise((response, reject) => {
        let div = document.createElement('div');
        let modalRoot = document.getElementById("modal-root");
        ReactDOM.render(<ContentDialogModal title={options.title} okLabel={options.okLabel} cancelLabel={options.cancelLabel} onlyOk={options.onlyOk} onClose={(result: boolean) => {
            response(result);
            ReactDOM.unmountComponentAtNode(div);
            modalRoot!.removeChild(div);
        }}>{options.text}</ContentDialogModal>, div);
        modalRoot!.appendChild(div);
    });
};

export { ContentDialogModal };
export default ContentDialog;