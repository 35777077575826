import React, { FC, useState } from 'react';
import { Whisper, Popover, Dropdown } from 'rsuite';

export interface ContextMenuEntry {
    title?: React.ReactElement | string,
    divider?: boolean,
    onSelect?: () => void
}

const ContextMenu: FC<{ entries: ContextMenuEntry[] }> = ({ entries, children }) => {
    const [triggerRef, setTriggerRef] = useState(undefined as  any)
    let idx = 0;

    const handleSelectMenu = (onSelect ?: () => void) => {
        triggerRef.hide();
        if (onSelect)
            onSelect();
    }

    return (
        <Whisper
            placement="leftStart"
            trigger="click"
            triggerRef={ref => {
                setTriggerRef(ref);
            }}
            speaker={<Popover>
                <Dropdown.Menu>
                    {entries.map(entry => {
                        if (entry.divider)
                            return <Dropdown.Item key={`menu-${idx++}`} divider />;
                        return <Dropdown.Item key={`menu-${idx++}`} onSelect={() => handleSelectMenu(entry.onSelect)}>{entry.title}</Dropdown.Item >
                    })}
                </Dropdown.Menu>
            </Popover>}>
            {children}
        </Whisper>
    )
}

export default ContextMenu;