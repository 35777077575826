import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';
import { IdentityReference } from './common-types'

export interface SynonymsSummary {
    id: string
    title: string
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface SynonymTransformerItem {
    target: string
    language: string
    source: string[]
}

export interface SynonymsItem {
    id: string
    title: string
    synonymTransformers: SynonymTransformerItem[]
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface NewSynonymsItem {
    title: string
}

export class SynonymsSummaryStore extends DataStore<SynonymsSummary> {
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/synonyms`, []);
    }
}

export class SynonymsItemStore extends FormStore<SynonymsItem, NewSynonymsItem> {
    constructor(baseUrl: string) {
        super(`${baseUrl}/api/synonyms`);
    }
}