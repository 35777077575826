import DataStore from 'src/core/stores/data-store';
import FormStore from 'src/core/stores/form-store';
import { IdentityReference } from './common-types';

export interface EntitySummary {
    id: string
    title: string
    entityType: string
    entityValuesSample: string[]
    entityValuesCount: number
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface EntityValueItem {
    language: string,
    value: string
}

export interface EntityItem {
    id: string
    title: string
    entityType: string
    entityValues: EntityValueItem[]
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface NewEntityItem {
    title: string
    entityType: string
    entityValues: EntityValueItem[]
    createdBy: IdentityReference
    createdOn: Date
    modifiedBy: IdentityReference
    modifiedOn: Date
}

export interface EntityPatchItem {
    title: string
    entityValues: EntityValueItem[]
}

export class EntitySummaryStore extends DataStore<EntitySummary> {
    private realBaseUrl: string

    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/entities`, []);
        this.realBaseUrl = baseUrl
    }

    public async setCollection(id: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${id}/entities`
    }

    public async pathEntity(entity: EntityPatchItem) {
        return await this.handleCallAsync(async () => {
            var result = await this.httpService.patch(this.baseUrl, entity)
            return result.data
        })
    }
}

export class EntityItemStore extends FormStore<EntityItem, NewEntityItem> {
    private realBaseUrl: string

    constructor(baseUrl: string) {
        super(`${baseUrl}/api/collections/undefined/entities`);
        this.realBaseUrl = baseUrl
    }

    public async setCollection(collectionId: string) {
        this.baseUrl = `${this.realBaseUrl}/api/collections/${collectionId}/entities`
    }
}