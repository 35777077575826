import React, { FC, useEffect, useState } from 'react';
import { Panel, Loader } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import HttpService from 'src/core/services/http.service';
import { Line } from 'react-chartjs-2';
import { container } from 'src/inversify.config';

export interface ActivityDashboardProps {

}

const ActivityDashboard: FC<ActivityDashboardProps & WithTranslation> = ({ t }) => {
    const httpService = container.get(HttpService);
    const [isBusy, setIsBusy] = useState(true);
    const [data, setData] = useState<any>(undefined);

    const onLoad = async () => {
        try {
            var result = await httpService.get<any>(`/api/admin/statistics/activity`);

            let table = result.data.tables[0];
            let rows = table.rows;

            var items = rows.orderByDescending((o: any) => o[0]).map((row: any) => {
                return {
                    x: row[0],
                    y: row[1]
                } as any;
            });

            var data = {
                datasets: [
                    {
                        label: t('Interactions'),
                        fill: true,
                        data: items,

                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: 'rgba(75,192,192,1)',
                    }
                ]
            }
            setData(data)
            setIsBusy(false)
        } finally {
            setIsBusy(false)
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <Panel header={<label style={{ fontSize: 14 }}>{t("Activity in last 7 days")}</label>} bordered style={{ padding: 10, width: '100%' }}>
            {isBusy && <Loader />}
            <div style={{ height: 200 }}>
                {!isBusy && data && <Line
                    data={data}
                    width={100}
                    height={50}
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                type: "time",
                                time: {
                                    unit: 'day'
                                },
                                scaleLabel: {
                                    display: true,
                                    labelString: t("date")
                                }
                            }],
                            yAxes: [{
                                scaleLabel: {
                                    display: true,
                                    labelString: t("Interactions")
                                }
                            }]
                        },
                        legend: {
                            display: false,
                        }
                    }}
                />}
            </div>
        </Panel>
    )
}

export default withTranslation()(ActivityDashboard);