import React, { FC, useEffect, useRef, useState } from 'react';
import { createState, State, useState as hsUseState, StateMethodsDestroy } from '@hookstate/core';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withIdentity, IdentityProps } from 'src/core/services/authentication';
import { clone, formatDateTime } from 'src/core/utils/object';
import { container } from 'src/inversify.config';
import { CrawlerItemStore } from 'src/stores/crawlers-store';
import { ListState, Query } from 'src/core/stores/data-store';
import { Button, Icon, InputGroup, Message, Tooltip, Whisper, Uploader, Alert, Loader } from 'rsuite';
import { TableModel } from 'src/core/ui/table/table-model';
import Table from 'src/core/ui/table';
import RegionContainer from 'src/core/ui/frame/region-content';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import SearchBox from 'src/core/ui/form/search-box';
import { formatMessage } from 'src/core/services/http.service';
import { strict } from 'assert';


const CrawlerLogsPage: FC<{ collectionId: string, crawlerId: string } & WithTranslation & IdentityProps> = ({ t, collectionId, crawlerId, identity, ...props }) => {
    const crawlerLogsStore = container.get(CrawlerItemStore)
    crawlerLogsStore.setCollection(collectionId)
    const logs = hsUseState({
        count: 0,
        isBusy: false,
        errorMessage: "",
        items: []
    } as ListState<any>)
    const [query, setQuery] = useState({
        searchQuery: '',
        orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 50
    } as Query)

    const onChange = async (query: Query) => {
        setQuery(query)
        logs.isBusy.set(true)
        try {
            const result = await crawlerLogsStore.getLogs(crawlerId)
            logs.items.set(result)
        }
        catch (error) {
            logs.errorMessage.set(formatMessage(error))
        }
        finally {
            logs.isBusy.set(false)
        }
    }

    const onSearch = async (q?: string) => {
        let newQuery = clone(query || {}) as Query;
        newQuery.skip = 0;
        newQuery.searchQuery = q || "";
        await onChange(newQuery);
    }

    useEffect(() => {
        onChange(query)
    }, [query]);

    const tableModel = {
        query: query,
        loading: logs.isBusy.value,
        columns: [
            {
                field: 'icon',
                width: 40,
                title: "",
                renderer: (item) => (item.error ? <Icon icon="warning" style={{ color: 'red' }} /> : <Icon icon="info-circle" />)
            },
            {
                width: 250,
                field: 'name',
                title: t('Action'),
                renderer: (item) => <span>{item.name}</span>
            },
            {
                width: 200,
                field: 'timestamp',
                title: t('Timestamp'),
                renderer: (item) => <span>{formatDateTime(item.timestamp)}</span>
            },
            {
                flow: 1,
                field: 'error',
                title: t('Error'),
                renderer: (item) => <span>{item.error}</span>
            }
        ],
        data: logs,
        paginable: false
    } as TableModel<any>;
    
    return (
        <RegionContainer
            toolbarLeft={
                [                    
                    <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload list")}</Tooltip>}>
                        <Button placement="left" onClick={() => onChange(query)}>
                            <Icon icon="reload" />
                        </Button>
                    </Whisper>
                ]
            }
            toolbarRight={
                [
                    <FlexboxGridItem key="search">
                        <InputGroup inside>
                            <SearchBox key='searchBox' onSearch={onSearch} />
                        </InputGroup>
                    </FlexboxGridItem>
                ]
            }>
            {logs.errorMessage.value && <Message type="error" description={formatMessage(logs.errorMessage.value)} />}
            <Table model={tableModel} onChange={onChange} />
        </RegionContainer>
    )
}

export default withIdentity(withTranslation()(CrawlerLogsPage))