//type Resolver = (path: string, params: { [param: string]: string }) => string;
interface UrlDictionary  { [name: string]: string }
export class BreadcrumbProvider {
    private urls: UrlDictionary = {}
    //private resolvers: Resolver[] = []
    //private ignoredRoutes: string[] = []

    //public addIgnoredPath(pattern: string) {
    //    this.ignoredRoutes.push(pattern)
    //}

    //public setResolver(pattern: string, resolver: Resolver) {
    //    this.resolvers.push(resolver)
    //}

    public setTitle(uri: string, title: string) {
        this.urls[uri]=title;
    }

    public getTitle(currentUri: string, targetUri: string, targetComponent: string) {
        if (this.urls[targetUri]!=undefined)
            return this.urls[targetUri]
        return this.capitalize(targetComponent)
    }

    private capitalize (s: string) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
} 