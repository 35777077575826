import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { container } from 'src/inversify.config';
import { EndpointItemStore } from 'src/stores/endpoints-store';
import { Button, Panel, Icon, Loader, Message, FormGroup, FormControl, HelpBlock, TagPicker, ControlLabel, Tooltip, Whisper, Input, Alert, Form } from 'rsuite';
import { formatMessage } from 'src/core/services/http.service';
import PageContainer from 'src/core/ui/frame/page-content';

const EndpointsPage: FC<RouteComponentProps<{ endpointId: string }> & WithTranslation> = ({ t, match }) => {
  const endpointsStore = container.get(EndpointItemStore)
  const endpointsState = endpointsStore.state
  const isBusy = endpointsState.isBusy.value
  const errorMessage = endpointsState.errorMessage.value
  const endpointId = match.params.endpointId
  const title = endpointsState.item.value && endpointsState.item.title.value || t("Endpoints")

  const onSave = async () => {
    await endpointsStore.save(endpointId, endpointsState.item.value)
    Alert.info(t('Endpoints list has been saved successfully!'));
  }

  useEffect(() => {
    endpointsStore.load(endpointId)
  }, [endpointId]);

  const onReload = async () => {
    await endpointsStore.load(endpointId)
  }

  const blackList = endpointsState.item.value && endpointsState.item.blackList.value ? endpointsState.item.blackList.value.map(o => ({ label: o, value: o })) : [];

  return (
    <PageContainer title={<div><Icon icon="language" />&nbsp;<span>{title}</span></div>}
      toolbarLeft={
        [
          <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload this document")}</Tooltip>}>
            <Button placement="left" onClick={() => onReload()}>
              <Icon icon="reload" />
            </Button>
          </Whisper>
        ]
      }
      toolbarRight={
        [
          <Whisper key='save' placement="top" trigger="hover" speaker={<Tooltip>{t("Save this document")}</Tooltip>}>
              <Button placement="left" appearance="primary" onClick={() => onSave()}>
                  <Icon icon="save" /> {t("Save")}
              </Button>
          </Whisper>
        ]
      }>
      {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
      {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
      {/* !isBusy && <Message description={t("You don't have access to configure this search endpoint")} /> */}
      {endpointsState.item.value && <Form fluid className="fluid-tooltip">
        <FormGroup>
          <ControlLabel>{t("Title")}</ControlLabel>
          <FormControl name="title" readOnly value={endpointsState.item.title.value} onChange={val => endpointsState.item.title.set(val)} />
          <HelpBlock tooltip>{t("The title of the search endpoint")}</HelpBlock>
        </FormGroup>
        <FormGroup>
          <ControlLabel>{t("Blocked users")}</ControlLabel>
          <TagPicker name="blackList" creatable value={endpointsState.item.blackList.value ?? []} onChange={val => endpointsState.item.blackList.set(val)} data={blackList} style={{ width: '100%' }} />
          <HelpBlock tooltip>{t("Language is very important since AI algorithms are usually language specific.")}</HelpBlock>
        </FormGroup>
      </Form>}
    </PageContainer>
  )
}

export default withTranslation()(withRouter(EndpointsPage))