import React, { useEffect } from 'react'
import { Button, FlexboxGrid, Grid, Icon, Input, InputGroup, List, Loader, Message, Popover, Row, Tag, Whisper } from 'rsuite';
import { EditorState, CompositeDecorator, ContentState, Modifier, convertToRaw, SelectionState, KeyBindingUtil, getDefaultKeyBinding, ContentBlock } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
//import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
//import '@draft-js-plugins/mention/lib/plugin.css';
import './intent-editor.css'
import { WhisperInstance } from 'rsuite/lib/Whisper';
import { find } from 'lodash';

export interface IntentEditorProps {
    /** Habilita información para desarrolladores */
    debugMode?: boolean,
    /** Texto de inicio para el editor */
    value: string,
    /** Texto que se monstrará en el editor cuando el campo value está vacío */
    placeholder?: string,
    /** Mediante este método se notificará los cambios producidos en el editor */
    onChange: (value: string) => void,
    /** Metodo para obtener la lista de entidades */
    getEntities: (prefix: string, take?: number) => Promise<string[]>,
    /** Metodo para obtener la lista de valores según una entidad */
    getEntityValues: (entity: string) => Promise<string[]>,
    /** Metodo para crear o actualizar entidad y valor */
    upsertEntity: (entity: string, values: string[]) => Promise<void>,
    /** estilos del control **/
    style?: React.CSSProperties
}
interface ReplaceItem {
    start: number,
    end: number,
    label: string,
    text: string,
    entity: string
}
interface EntityItem {
    label: string,
    text: string,
    blockKey: string,
    start: number,
    end: number
}

// inline toolbar plugin
//const inlineToolbarPlugin = createInlineToolbarPlugin();
//const { InlineToolbar } = inlineToolbarPlugin;

const { hasCommandModifier } = KeyBindingUtil;
const IntentLabelRegex = /\[(.*?)\]\s*\(@?(.*?)\)/g;
const NewEntityRegex = /^[a-zA-Z]([a-zA-Z0-9-_]+\:?)*$/gm;
const ENTITY_TYPE = "INTENT";

const validNewEntity = (entityName: string) => {
    return NewEntityRegex.test(entityName);
}

const findWithRegex = (regex: RegExp, text: string, callback: (start: number, end: number, label: string, text: string, entity: string) => void) => {
    let matchArr, start, end;
    while ((matchArr = regex.exec(text)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (matchArr.index === regex.lastIndex) {
            regex.lastIndex++;
        }
        start = matchArr.index;
        end = start + matchArr[0].length;
        callback(start, end, matchArr[0], matchArr[1], matchArr[2]);
    }
};

const getDataFromIntentLabel = (label: string) => {
    if (!label) return null;
    //[etiquetado](@etiqueta1:clase1)
    let text = label.match(/\[([^)]+)\]/)![1]; // corchetes
    if (!text) return null;
    let entity = label.match(/\(([^)]+)\)/)![1]; // parentesis
    if (!entity) return null;
    entity = entity.replace('@', '');
    return [text, entity];
}

const findIntentLabelEntities = (block: ContentBlock, callback: (start: number, end: number) => void, content: ContentState) => {
    block.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                content.getEntity(entityKey).getType() === ENTITY_TYPE
            );
        },
        callback
    );
}

const IntentLabel = (props: any) => {
    const { label, text, entity } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <span style={{
            backgroundColor: '#345678',
            color: "#fff",
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            fontSize: 14,
            padding: 2
        }}>
            <Whisper placement="auto" trigger="hover" speaker={
                <Popover title={<React.Fragment><Icon icon="tags" /> {text}</React.Fragment>} className="intent-popover">
                    <FlexboxGrid>
                        <FlexboxGrid.Item colspan={22}><Tag>{entity}</Tag></FlexboxGrid.Item>
                    </FlexboxGrid>
                </Popover>
            }>
                <label>{props.children}</label>
            </Whisper>
        </span>
    );
};

export const IntentEditor: React.FC<IntentEditorProps> = ({
    debugMode,
    value,
    placeholder,
    onChange,
    getEntities,
    getEntityValues,
    upsertEntity,
    style
}: IntentEditorProps) => {
    const decorator = new CompositeDecorator([
        {
            strategy: findIntentLabelEntities,
            component: IntentLabel,
        },
    ]);

    const editorWrapperRef = React.useRef<HTMLDivElement>();
    const newIntentTriggerRef = React.useRef<WhisperInstance>();
    const setIntentTriggerRef = React.useRef<WhisperInstance>();
    const [data, setData] = React.useState(''); // DEBUG
    const [prebuildSuggestion, setPrebuildSuggestion] = React.useState([] as string[]);
    const [listSuggestion, setlistSuggestion] = React.useState([] as string[]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    const [editorState, setEditorState] = React.useState(() => buildEntitiesFromRawText(decorator, value) || EditorState.createEmpty(decorator));
    const [rawTextState, setRawTextState] = React.useState(value);
    const initialized = React.useRef(false);

    useEffect(() => {
        if (!initialized.current || rawTextState !== value) {
            initialized.current = true
            if (!value) {
                setEditorState(EditorState.createEmpty(decorator));
            }
            else {
                const entities = buildEntitiesFromRawText(decorator, value);
                if (entities)
                    setEditorState(entities);
            }
            buildSuggestions();
        }
    }, [value]);

    function buildEntitiesFromRawText(decorator: CompositeDecorator, value: string) {
        if (value) {
            let textToEditor = value;
            let replaces = [] as ReplaceItem[];
            let startIndexCorrection = 0;

            findWithRegex(IntentLabelRegex, value, (start: number, end: number, label: string, text: string, entity: string) => {
                if (start>=0 && end>0 && label && text && entity) {
                    const startIndex = start - startIndexCorrection;
                    const endIndex = startIndex + text.length;
                    replaces.push({
                        label: label,
                        text: text,
                        entity: entity,
                        start: startIndex,
                        end: endIndex
                    });
                    startIndexCorrection += label.length - text.length; // correcion entre las cadenas con etiquetas y sin etiquetas
                    textToEditor = textToEditor.replace(label, text);
                }
            });

            let editorWithValueText = EditorState.createWithContent(ContentState.createFromText(textToEditor), decorator);
            let contentState = editorWithValueText.getCurrentContent();
            const firstBlock = contentState.getFirstBlock();
            const blockKey = firstBlock.getKey();

            replaces.forEach((replace) => {
                let ec = replace.entity.split(':');
                const entity = contentState.createEntity(ENTITY_TYPE, 'IMMUTABLE', {
                    label: replace.label,
                    text: replace.text,
                    entity: replace.entity
                });

                const entityKey = entity.getLastCreatedEntityKey();
                const blockSelection = SelectionState
                    .createEmpty(blockKey)
                    .merge({
                        anchorOffset: replace.start,
                        focusOffset: replace.end,
                    });

                contentState = Modifier.replaceText(contentState, blockSelection, replace.text, undefined, entityKey);
            });

            const newEditorState = EditorState.push(editorWithValueText, contentState, 'insert-characters');
            return newEditorState;
        }
    }

    function buildRawTextFromEntities(contentState: ContentState): string {
        const entities = [] as EntityItem[];
        contentState.getBlocksAsArray().forEach(block => {
            let selectedEntity = {} as EntityItem;
            block.findEntityRanges(
                character => {
                    if (character.getEntity() !== null) {
                        const entity = contentState.getEntity(character.getEntity());
                        const entityData = entity.getData();
                        if (entity.getType() === ENTITY_TYPE) {
                            selectedEntity = {
                                label: entityData.label,
                                text: entityData.text,
                                blockKey: block.getKey(),
                                start: 0,
                                end: 0
                            };
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    return false;
                },
                (start, end) => {
                    entities.push({ ...selectedEntity, start, end });
                }
            );
        });

        let rawText = contentState.getPlainText();
        let indexCorrection = 0;
        entities.forEach(entity => {
            const startIndex = entity.start + indexCorrection;
            const endIndex = entity.end + indexCorrection;
            rawText = rawText.substring(0, startIndex) + entity.label + rawText.substring(endIndex);
            indexCorrection += entity.label.length - entity.text.length; // correcion entre las cadenas con etiquetas y sin etiquetas
        });

        return rawText;
    }

    const onChangeEditor = (newState: EditorState) => {
        setEditorState(newState);
        triggerOnChange(newState);

        const newselection = newState.getSelection();
        if (newselection.getAnchorOffset() !== newselection.getFocusOffset() && newselection.getHasFocus()) {
            const oldselectionText = getTextSelection(editorState.getCurrentContent(), editorState.getSelection());
            const newselectionText = getTextSelection(newState.getCurrentContent(), newState.getSelection());
            if (oldselectionText !== newselectionText && newselectionText.trim()) {
                setIntentTriggerRef.current!.open();
                //console.log(newselectionText);
                //inputLabelRef.current.focus();
            }
        }
    }

    function myKeyBindingFn(e: React.KeyboardEvent<{}>): string | null {
        if (e.key === "@" && !hasCommandModifier(e as any)) {
            return 'create-intent';
        }
        if (e.key === "Escape" && !hasCommandModifier(e as any)) {
            return 'close-dialogs';
        }
        return getDefaultKeyBinding(e as any);
    }

    function handleKeyCommand(command: string, editorState: EditorState) {
        if (command === "create-intent") {
            newIntentTriggerRef.current!.open();
            return "handled";
        }
        if (command === "close-dialogs") {
            newIntentTriggerRef.current!.close();
            setIntentTriggerRef.current!.close();
            return "handled";
        }

        return "not-handled";
    }

    const setLabel = (e: any, selected: string, isNew: boolean = false) => {
        e.preventDefault();
        let labelToInsert = null;
        let inputValue = e.target.value;
        if (isNew && validNewEntity(inputValue)) {
            labelToInsert = `@${inputValue}`;
        }
        else {
            if (selected)
                labelToInsert = selected;

            if (!labelToInsert)
                labelToInsert = listSuggestion.length > 0 ? listSuggestion[0] : null;
        }

        if (labelToInsert) {
            setIntentTriggerRef.current!.close();
            const selection = editorState.getSelection();
            const selectedText = getTextSelection(editorState.getCurrentContent(), selection);
            const newEditorState = getStateWithReplace(editorState, labelToInsert, selectedText);
            upsertEntity(labelToInsert, [selectedText]);
            setEditorState(newEditorState);
            triggerOnChange(newEditorState);
        }
    }

    const triggerOnChange = (state: EditorState) => {
        const newContent = state.getCurrentContent();
        const rawValue = buildRawTextFromEntities(newContent);
        if (rawTextState !== rawValue) {
            setRawTextState(rawValue);
            onChange(rawValue);
            console.log(rawValue);
        }
    }

    const createLabel = async (e: any, selected: string) => {
        e.preventDefault();
        let labelToInsert = null;

        if (selected)
            labelToInsert = selected;

        if (!labelToInsert)
            labelToInsert = listSuggestion.length > 0 ? listSuggestion[0] : null;

        if (labelToInsert) {
            newIntentTriggerRef.current!.close();
            const label = `[_](${labelToInsert})`;
            const dataFromIntent = getDataFromIntentLabel(label);
            if (dataFromIntent) {
                const [_, entity] = dataFromIntent;
                const labelText = await getFirstOrDefaultValue(entity);
                const newEditorState = getStateWithReplace(editorState, labelToInsert, labelText);
                setEditorState(newEditorState);
            }
        }
    }

    const getStateWithReplace = (editorState: EditorState, selectedLabel: string, labelText: string) => {
        const newLabel = labelText.trim();
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const label = `[${newLabel}](${selectedLabel})`;
        const dataFromIntent = getDataFromIntentLabel(label);
        if (dataFromIntent) {
            const [text, entity] = dataFromIntent;
            const entityEditor = contentState.createEntity(ENTITY_TYPE, 'IMMUTABLE', {
                text: text,
                entity: entity,
                label: label
            });
            const blockSelection = selection
                .merge({
                    focusOffset: selection.getFocusOffset() - (labelText.length - newLabel.length),
                });
            const entityKey = entityEditor.getLastCreatedEntityKey();
            const textWithEntity = Modifier.replaceText(contentState, blockSelection, newLabel, undefined, entityKey);
            const newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters');

            return newEditorState;
        }
        else {
            return editorState;
        }
    }

    const getTextSelection = (contentState: ContentState, selection: SelectionState, blockDelimiter?: string) => {
        blockDelimiter = blockDelimiter || '\n';
        var startKey = selection.getStartKey();
        var endKey = selection.getEndKey();
        var blocks = contentState.getBlockMap();

        var lastWasEnd = false;
        var selectedBlock = blocks
            .skipUntil(function (block) {
                return block!.getKey() === startKey;
            })
            .takeUntil(function (block) {
                var result = lastWasEnd;

                if (block!.getKey() === endKey) {
                    lastWasEnd = true;
                }

                return result;
            });

        return selectedBlock
            .map(function (block) {
                var key = block!.getKey();
                var text = block!.getText();

                var start = 0;
                var end = text.length;

                if (key === startKey) {
                    start = selection.getStartOffset();
                }
                if (key === endKey) {
                    end = selection.getEndOffset();
                }

                text = text.slice(start, end);
                return text;
            })
            .join(blockDelimiter);
    }

    const getSelectedText = () => {
        const selection = editorState.getSelection();
        const selectedText = getTextSelection(editorState.getCurrentContent(), selection);
        return selectedText;
    }

    const buildSuggestions = async () => {
        setLoadingModal(true);
        const entities = await getEntities('', 5);
        setPrebuildSuggestion(entities);
        setlistSuggestion(entities);
        setLoadingModal(false);
    }

    var lastRun = new Date()
    var process: any = undefined;
    const searchSuggestion = async (text: string) => {        
        clearTimeout(process);
        if (lastRun > new Date(Date.now() - 500)) {
            process = setTimeout(() => searchSuggestion(text), 1000)
            return;
        }
        setLoadingModal(true);
        const entities = await getEntities(text);
        setlistSuggestion(entities);
        setLoadingModal(false);
    }

    const getFirstOrDefaultValue = async (entityName: string) => {
        const values = await getEntityValues(entityName);
        let labelText = entityName; // valor no encontrado
        if (values && values.length > 0)
            labelText = values[0];
        return labelText;
    }

    const speakerIntentLabel = (
        <Popover title={<React.Fragment><Icon icon="tags" /> Etiquetar texto: {getSelectedText()}</React.Fragment>} className="label-popover">
            <Grid fluid className="grid-popover">
                <Row>
                    <span>Seleccione la etiqueta para el texto seleccionado.</span>
                </Row>
                <Row>
                    <InputGroup>
                        <Input
                            autoFocus
                            size="sm"
                            onChange={(value: any) => searchSuggestion(value)}
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.code === 'Escape') { e.stopPropagation(); setIntentTriggerRef.current!.close(); } }}
                            onPressEnter={(e: any) => setLabel(e, '', listSuggestion.length === 0)} />
                        <InputGroup.Button>
                            <Icon icon="search" />
                        </InputGroup.Button>
                    </InputGroup>
                </Row>
                <Row>
                    {loadingModal &&
                        <Loader content="Loading..." />
                    }
                    {!loadingModal && listSuggestion && listSuggestion.length > 0 &&
                        <List size='sm' hover bordered className='label-list'>
                            {listSuggestion.map((item, index) => {
                                return (
                                    <List.Item key={index} index={index}>
                                        <label onClick={(e) => setLabel(e, item)}>{item}</label>
                                    </List.Item>
                                )
                            }
                            )}
                        </List>
                    }
                    {(!loadingModal && !listSuggestion || listSuggestion.length === 0) &&
                        <React.Fragment>
                            <Message
                                showIcon
                                type="info"
                                description="No se ha encontrado ninguna etiqueta. Para crear ésta etiqueta pulse Enter." />
                        </React.Fragment>
                    }
                </Row>
                <Row>
                    <Button appearance="link" onClick={e => { e.stopPropagation(); setIntentTriggerRef.current!.close(); }}>Cerrar</Button>
                </Row>
            </Grid>
        </Popover>
    );

    const speakerCreateIntent = (
        <Popover title={<React.Fragment><Icon icon="tags" /> Agregar etiqueta</React.Fragment>} className="label-popover">
            <Grid fluid className="grid-popover">
                <Row>
                    <span>Seleccione la etiqueta que desea agregar.</span>
                </Row>
                <Row>
                    <InputGroup>
                        <Input
                            autofocus
                            size="sm"
                            onChange={(value: any) => searchSuggestion(value)}
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.code === 'Escape') { e.stopPropagation(); newIntentTriggerRef.current!.close(); } }}
                            onPressEnter={(e: any) => createLabel(e, '')} />
                        <InputGroup.Button>
                            <Icon icon="search" />
                        </InputGroup.Button>
                    </InputGroup>
                </Row>
                <Row>
                    {loadingModal &&
                        <Loader content="Loading..." />
                    }
                    {!loadingModal && listSuggestion && listSuggestion.length > 0 &&
                        <List size='sm' hover bordered className='label-list'>
                            {listSuggestion.map((item, index) => {
                                return (
                                    <List.Item key={index} index={index}>
                                        <label onClick={(e) => createLabel(e, item)}>{item}</label>
                                    </List.Item>
                                )
                            }
                            )}
                        </List>
                    }
                    {!loadingModal && !listSuggestion || listSuggestion.length === 0 &&
                        <Message
                            showIcon
                            type="warning"
                            description="No se ha encontrado ninguna etiqueta. Para crear una nueva etiqueta, seleccione texto, escriba la etiqueta y pulse Enter." />
                    }
                </Row>
            </Grid>
        </Popover>
    );

    // DEBUG MODE
    const getContentStateInJSON = () => {
        const rawJson = convertToRaw(editorState.getCurrentContent());
        const jsonStr = JSON.stringify(rawJson, null, 1);

        setData(jsonStr);
    };

    return (
        <div style={style}>
            <Whisper
                ref={newIntentTriggerRef}
                trigger="none"
                placement={'bottom'}
                enterable
                speaker={speakerCreateIntent}
                //onEntered={() => {newInputLabelRef.current?.focus()}}
                onExited={() => setlistSuggestion(prebuildSuggestion)}>
                <Whisper
                    ref={setIntentTriggerRef}
                    placement="bottom"
                    speaker={speakerIntentLabel}
                    trigger="none"
                    onExited={() => setlistSuggestion(prebuildSuggestion)}
                    enterable>
                    <div className='editor-wrapper' ref={editorWrapperRef as any}>
                        <Editor
                            editorState={editorState}
                            //plugins={[inlineToolbarPlugin]}
                            onChange={onChangeEditor}
                            placeholder={placeholder}
                            keyBindingFn={myKeyBindingFn}
                            handleKeyCommand={handleKeyCommand}
                        />
                        {/*
                <InlineToolbar>
                    {
                        (externalProps) => (
                            <React.Fragment>
                                <div className="label-wrapper">
                                    <Whisper
                                        ref={setIntentTriggerRef}
                                        placement="auto"
                                        speaker={speakerIntentLabel}
                                        trigger="hover"
                                        onExited={() => setlistSuggestion(prebuildSuggestion)}
                                        enterable >
                                        <button><Icon icon="tags" /> Etiquetar</button>
                                    </Whisper>
                                </div>
                            </React.Fragment>
                        )
                    }
                </InlineToolbar>
                */}
                        {/*DEBUG MODE*/}
                        {debugMode &&
                            <React.Fragment>
                                <button style={{ marginTop: "30px", marginLeft: "0px" }} className={"var"} onClick={getContentStateInJSON}>Fetch Content State</button>
                                <div style={{ background: "#345678", color: "#fff", padding: "20px" }}>
                                    <pre>{data}</pre>
                                </div>
                            </React.Fragment>
                        }
                        {/*DEBUG MODE*/}
                    </div>
                </Whisper>
            </Whisper>
        </div>
    )
}