import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { clone, formatDate } from 'src/core/utils/object';
import { container } from 'src/inversify.config';
import { DocumentSummary, DocumentSummaryStore } from 'src/stores/documents-store';
import { Query } from 'src/core/stores/data-store';
import { Button, Icon, InputGroup, Message, Tooltip, Whisper, Uploader, Alert, Dropdown } from 'rsuite';
import { TableModel } from 'src/core/ui/table/table-model';
import { formatMessage } from 'src/core/services/http.service';
import Table from 'src/core/ui/table';
import RegionContainer from 'src/core/ui/frame/region-content';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import SearchBox from 'src/core/ui/form/search-box';
import { Link } from 'react-router-dom';
import { withIdentity, IdentityProps } from 'src/core/services/authentication';
import showNewDocumentDialog from './document-new';
import ContextMenu from 'src/core/ui/table/context-menu';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import { useHistory } from "react-router-dom";

const DocumentsPage: FC<{ collectionId: string } & WithTranslation & IdentityProps> = ({ t, collectionId, identity }) => {

  const history = useHistory();
  const documentsStore = container.get(DocumentSummaryStore)
  documentsStore.setCollection(collectionId)
  const documentState = documentsStore.state
  const [query, setQuery] = useState({
    searchQuery: '',
    orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
    skip: 0,
    take: 15
  } as Query)

  const onChange = async (query: Query) => {
    documentsStore.load(query)
    setQuery(query)
  }

  const onSearch = async (q?: string) => {
    let newQuery = clone(query || {}) as Query;
    newQuery.skip = 0;
    newQuery.searchQuery = q || "";
    await onChange(newQuery);
  }

  const onNewDocument = async (contentType: string) => {
    let result = await showNewDocumentDialog(collectionId, contentType)
    if (result) {
      history.push(`/collections/${collectionId}/documents/${result}`);
    } else {
      await onChange(query)
    }
  }
  const onDeleteDocument = async (collectionId: string) => {
    await documentsStore.delete(collectionId)
  }

  const onReprocess = async () => {
    ConfirmDialog({
      text: t("Are you sure want to re-process all the documents in the collection? This might take a while and will use a lot of server resources."),
      type: "warning",
      okLabel: t("Yes"),
      cancelLabel: t("No"),
    }).then(async result => {
      if (result) {
        documentsStore.reprocess(collectionId);
      }
    });
  }

  useEffect(() => {
    documentsStore.load(query)
  }, [query]);

  const tableModel = {
    query: query,
    loading: documentState.isBusy.value,
    columns: [
      {
        field: 'icon',
        width: 40,
        title: "",
        renderer: (item) => <Link to={`/collections/${collectionId}/documents/${item.id}`}><Icon icon={item.contentType == 'dialog' ? "comments-o" : "file-text-o" as any} /></Link>
      },
      {
        field: 'title',
        title: t('Title'),
        flow: 1,
        renderer: (item) => <Link to={`/collections/${collectionId}/documents/${item.id}`}><span>{item.title}</span></Link>
      },
      {
        field: 'status',
        title: t('Status'),
        width: 150,
        renderer: (item) => {
          let icon = 'file-text-o';
          let text = '';
          let color = '';
          if (item.status == "Published") {
            icon = 'check-circle-o';
            text = t("Published");
            color = '#4caf50';
          } else if (item.status == "Error") {
            icon = 'exclamation-triangle';
            text = t("Error");
            color = '#f44336';
            //} else if (item.version && item.version.draft > 0 && item.version.published < item.version.draft && item.version.trained >= item.version.draft) {
            //    icon = 'exclamation-circle';
            //    text = t("Trained");
            //    color = '#ffb300';
          } else {
            icon = 'exclamation-circle';
            text = t(item.status);
            color = '#ffb300';
          }
          return <span><Icon icon={icon as any} style={{ color: color }} />&nbsp;{text}</span>
        }
      },
      {
        field: 'createdBy',
        title: t('Created by'),
        renderer: (item) => <span>{item.createdBy && item.createdBy.identityDisplayName}</span>
      },
      {
        field: 'createdOn',
        title: t('Created on'),
        renderer: (item) => <span>{formatDate(item.createdOn)}</span>
      },
      {
        field: 'modifiedBy',
        title: t('Modified by'),
        renderer: (item) => <span>{item.modifiedBy && item.modifiedBy.identityDisplayName}</span>
      },
      {
        field: 'modifiedOn',
        title: t('Modified on'),
        renderer: (item) => <span>{formatDate(item.modifiedOn)}</span>
      },
      {
        field: 'actions',
        width: 60,
        title: "",
        renderer: (item) => <ContextMenu
          entries={[
            //{
            //    title: <div><Icon icon="download" /> {t("Download a copy")}</div>,
            //    onSelect: () => {

            //    }
            //},
            //{ divider: true },
            {
              title: <div><Icon icon="trash2" /> {t("Delete document")}</div>, onSelect: () => {
                ConfirmDialog({
                  text: t("Are you sure want to delete the selected document?"),
                  type: "warning",
                  okLabel: t("Yes"),
                  cancelLabel: t("No"),
                }).then(result => {
                  if (result) {
                    onDeleteDocument(item.id);
                  }
                });
              }
            },
          ]}>
          <Link to="#"><Icon icon="more" /></Link>
        </ContextMenu>
      },
    ],
    data: documentState,
    paginable: true
  } as TableModel<DocumentSummary>;

  return (
    <RegionContainer
      toolbarLeft={
        [
          <Whisper key='addFile' placement="top" trigger="hover" speaker={<Tooltip>{t("New document")}</Tooltip>}>
            <Dropdown
              title={t("New")}
              renderTitle={children => {
                return <Button appearance="primary" style={{ width: 38 }}><Icon icon="plus" /></Button>;
              }}>
              <Dropdown.Item onSelect={() => onNewDocument('document')}><Icon icon="file-text-o" /> {t("New document")}</Dropdown.Item>
              <Dropdown.Item onSelect={() => onNewDocument('dialog')}><Icon icon="comments-o" /> {t("New conversation")}</Dropdown.Item>
            </Dropdown>
          </Whisper>,
          <Whisper key='uploadDocument' placement="top" trigger="hover" speaker={<Tooltip>{t("Upload document")}</Tooltip>}>
            <Uploader
              disabledFileItem
              fileListVisible={false}
              multiple={false}
              withCredentials
              action={`/api/collections/${collectionId}/documents?access_token=${identity && identity.accessToken}`}
              style={{ display: 'inline-block' }}
              onSuccess={() => Alert.info(t('The document was upload successfully.'))}
              onError={() => Alert.error(t('An error has occurred while uploading the file.'))}>
              <Button placement="left" appearance="primary">
                <Icon icon="file-upload" />
              </Button>
            </Uploader>
          </Whisper>,
          <Whisper key='reindex' placement="top" trigger="hover" speaker={<Tooltip>{t("Re-process the collection")}</Tooltip>}>
            <Button placement="left" onClick={() => onReprocess()}>
              <Icon icon="flow" />
            </Button>
          </Whisper>,
          <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload list")}</Tooltip>}>
            <Button placement="left" onClick={() => onChange(query)}>
              <Icon icon="reload" />
            </Button>
          </Whisper>
        ]
      }
      toolbarRight={
        [
          <FlexboxGridItem key="search">
            <InputGroup inside>
              <SearchBox key='searchBox' onSearch={onSearch} />
            </InputGroup>
          </FlexboxGridItem>
        ]
      }>
      {documentState.errorMessage.value && <Message type="error" description={formatMessage(documentState.errorMessage.value)} />}
      <Table model={tableModel} onChange={onChange} />
    </RegionContainer>
  )
}

export default withIdentity(withTranslation()(DocumentsPage))