import React, { FC } from 'react';
import MarkdownEditor from '@uiw/react-md-editor';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface MarkdownEditorProps {
    value: string
    height?: number
    maxWidth?: number | string
    minWidth?: number | string
    width?: number | string
    onChange: (value?: string) => void;
}

const MarkdownEditorWidget: FC<MarkdownEditorProps & WithTranslation> = ({ value, onChange, height, maxWidth, minWidth, width }) => {
    return (
        <MarkdownEditor
            style={{ width: width || '100%', maxWidth: maxWidth, minWidth: minWidth }}
            value={value || ""}
            height={height}
            onChange={onChange}
        />
    )
}

export default withTranslation()(MarkdownEditorWidget);