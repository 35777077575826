import React, { FC } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import CollectionListPage from './collection-list';
import CollectionDetailPage from './collection-detail';
import DocumentsPage from './documents';
import CrawlersPage from './crawlers';
import EntitiesPage from './entities';

const CollectionIndex: FC<RouteComponentProps<any>> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.url}`} component={CollectionListPage} />
        <Route exact path={`${match.url}/:collectionId`} component={CollectionDetailPage} />
        <Route exact path={`${match.url}/:collectionId/documents`} component={CollectionDetailPage} />
        <Route path={`${match.url}/:collectionId/documents`} component={DocumentsPage} />
        <Route path={`${match.url}/:collectionId/crawlers`} component={CrawlersPage} />
        <Route path={`${match.url}/:collectionId/entities`} component={EntitiesPage} />
    </Switch>
}

export default withRouter(CollectionIndex)