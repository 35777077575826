import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
//import { setHeader } from 'src/core/ui/shell';
import { Link } from 'react-router-dom';

const InsightsHomePage: FC<RouteComponentProps<any> & WithTranslation> = ({ t, ...props }) => {
    //setHeader(t("Portal"))
    
    return (
        <div>
            <h1>{t("Insights")}</h1>
            <p>TODO: Este es el portal del usuario</p>
            <ul>
                <li><Link to="/insights/crawlers">{t("Crawlers")}</Link></li>
            </ul>
        </div>
    )
}

export default withTranslation()(withRouter(InsightsHomePage))