import React, { FC } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import SynonymsListPage from './synonyms-list';
import SynonymsDetailPage from './synonyms-detail';

const SynonymsIndex: FC<RouteComponentProps<any>> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.url}`} component={SynonymsListPage} />
        <Route exact path={`${match.url}/:synonymsId`} component={SynonymsDetailPage} />
    </Switch>
}

export default withRouter(SynonymsIndex)