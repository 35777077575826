import React, { FC } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import CrawlersDetailPage from './crawler-detail';

const CrawlersIndex: FC<RouteComponentProps<any>> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.path}/:crawlerId`} component={CrawlersDetailPage} />
    </Switch>
}

export default withRouter(CrawlersIndex)