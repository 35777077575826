import React, { FC, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Form, FormGroup, FormControl, ControlLabel, Modal, Message, Loader, Schema, HelpBlock } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { CrawlerPatchesItemStore, CrawlerPatchItem } from 'src/stores/crawlerPatches-store';
import { formatMessage } from 'src/core/services/http.service';
import { clone, create_UUID } from 'src/core/utils/object';
import CodeEditor from 'src/core/ui/form/code-editor';
import yaml from 'js-yaml';
const { StringType } = Schema.Types;

interface CrawlerPatchDialogProps {
    collectionId: string
    crawlerId: string
    patchId: string
    onClose: (result: boolean) => void
}

const CrawlerPatchDialog: FC<WithTranslation & CrawlerPatchDialogProps> = ({ t, collectionId, crawlerId, patchId, onClose }) => {
    const crawlerPatchStore = container.get(CrawlerPatchesItemStore)
    crawlerPatchStore.setCrawler(collectionId, crawlerId)
    const crawlerPatchState = crawlerPatchStore.state;
    const isBusy = crawlerPatchState.isBusy.value
    const errorMessage = crawlerPatchState.errorMessage.value

    const form = useRef<any>();
    const [formValue, setFormValue] = useState<CrawlerPatchItem>({} as any)

    useEffect(() => {
        if (patchId)
            crawlerPatchStore.load(patchId)
    }, [patchId])

    useEffect(() => {
        if (crawlerPatchState.item.value) {
            const value = clone<CrawlerPatchItem>(crawlerPatchState.item.value);
            const jsonObj = value.patch ? yaml.load(JSON.stringify(value.patch)) : {}
            value.patch = yaml.dump(jsonObj)
            setFormValue(value)
        }
    }, [crawlerPatchState.isBusy.value])

    const onSave = async () => {
        let isValid = await form!.current!.checkAsync();
        if (isValid) {
            const value = clone<CrawlerPatchItem>(formValue);
            value.patch = value ? yaml.load(value.patch) : {}
            let response = await crawlerPatchStore.save(patchId || create_UUID(), value);
            if (response) {
                setFormValue({} as any)
                onClose(true);
            }
        }
    };

    const model = Schema.Model({
        title: StringType().isRequired(t('Title is required.')),
        documentId: StringType().isRequired(t('The id of the document to patch is required.'))
    });

    return (
        <Modal backdrop="static" size='lg' show onHide={() => onClose(false)}>
            <Modal.Header>
                <h5>{t("Crawler patch")}</h5>
            </Modal.Header>
            <Modal.Body>
                {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
                {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
                {!isBusy && <Form fluid className="fluid-tooltip" model={model} ref={form} formValue={formValue} onChange={value => setFormValue(value as any)}>
                    <FormGroup>
                        <ControlLabel>{t("Document Id to patch")}</ControlLabel>
                        <FormControl name="documentId" />
                        <HelpBlock tooltip>{t("The id of the document you want to patch.")}</HelpBlock>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t("Title")}</ControlLabel>
                        <FormControl name="title" />
                        <HelpBlock tooltip>{t("A descriptive title of this patch")}</HelpBlock>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{t("Patch")}</ControlLabel>
                        <FormControl name="patch" accepter={CodeEditor as any} t={t} language="yaml" height={200} />
                        <HelpBlock tooltip>{t("A descriptive title of this patch")}</HelpBlock>
                    </FormGroup>
                </Form>}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isBusy} onClick={() => onSave()} appearance="primary">
                    {t("Save")}
                </Button>
                <Button disabled={isBusy} onClick={() => onClose(false)} appearance="subtle">
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const showCrawlerPatchDialog = (collectionid: string, crawlerId: string, patchId: string) => {
    let TranslatedCrawlerPatchDialog = withTranslation()(CrawlerPatchDialog);
    return new Promise((response, reject) => {
        let div = document.createElement('div');
        let modalRoot = document.getElementById("modal-root");
        ReactDOM.render(<TranslatedCrawlerPatchDialog collectionId={collectionid} crawlerId={crawlerId} patchId={patchId} onClose={(result: boolean) => {
            response(result);
            ReactDOM.unmountComponentAtNode(div);
            modalRoot!.removeChild(div);
        }} />, div);
        modalRoot!.appendChild(div);
    });
};

export default showCrawlerPatchDialog