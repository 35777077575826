import React, { useMemo } from 'react'
import { SelectPicker, TagPicker } from 'rsuite';
import ISO6391 from 'iso-639-1';

export interface LanguagePickerProps {
    /** Selecci�n m�ltiple */
    multi?: boolean
    /** Idioma selecionado en formato ISO 639-1 (dos letras) */
    value?: string | string[]
    /** Evento que se lanza al seleccionar un idioma */
    onChange: (value: string | string[]) => void
    /** Lista de idiomas disponibles (es, en, pt, ...) */
    availableLanguages?: string[],
    style?: React.CSSProperties
}

export const LanguagePicker: React.FC<LanguagePickerProps> = ({ value, onChange, availableLanguages, multi, ...props }) => {
    if (!availableLanguages)
        availableLanguages = ['en', 'es', 'pt']

    const getLanguageName = (lang: string) => {
        if (!lang) return lang;
        return ISO6391.getName(lang);
    }

    const data = useMemo(() => availableLanguages!.map(o => ({value: o, label: getLanguageName(o)})), [availableLanguages])

  if (multi) {
    return (
      <TagPicker name="language" value={Array.isArray(value) ? value : value ? [value] : [] } onChange={val => onChange(val)} data={data} style={{ width: '100%' }} />
    )
  } else {
    return (
      <SelectPicker name="language" value={Array.isArray(value) ? value[0] : value} onChange={val => onChange(val)} data={data} style={{ width: '100%' }} />
    )
  }
}