import React, { FC, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Form, FormGroup, FormControl, ControlLabel, Modal, Message, Loader, Schema, HelpBlock, TagPicker } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { DocumentItemStore, NewDocumentItem } from 'src/stores/documents-store';
import { formatMessage } from 'src/core/services/http.service';
import MarkdownEditor from 'src/core/ui/form/markdown-editor';
import { create_UUID } from 'src/core/utils/object';
const { StringType } = Schema.Types;

interface NewDocumentDialogProps {
    onClose: (result: boolean) => void
}

const CustomField: FC<{ name: string, label: string, accepter: any, error?: string, height?: number }> = ({ name, label, accepter, error, ...props }) => {
    return (
        <FormGroup className={error ? 'has-error' : ''}>
            <ControlLabel>{label} </ControlLabel>
            <FormControl
                name={name}
                accepter={accepter}
                errorMessage={error}
                {...props}
            />
        </FormGroup>
    );
}

const NewDocumentDialog: FC<{ collectionId: string, contentType: string } & WithTranslation & NewDocumentDialogProps> = ({ t, collectionId, contentType, onClose }) => {
  const newDocumentStore = container.get(DocumentItemStore);
  newDocumentStore.setCollection(collectionId);
  const newDocumentState = newDocumentStore.state;
  const isBusy = newDocumentState.isBusy.value
  const errorMessage = newDocumentState.errorMessage.value
  const form = useRef<any>();
  const [formValue, setFormValue] = useState<NewDocumentItem>({} as any)
  const [docId, setDocId] = useState<string>(create_UUID())

  const onNewItem = async () => {
    if (!formValue.body && contentType == 'dialog') {
      formValue.body = "{}"
    }
    formValue.contentType = contentType;
    let validation = await form!.current!.checkAsync();
    if (validation && !validation.hasError) {
      let response = await newDocumentStore.create(formValue, docId);
      if (response) {
        setDocId(create_UUID())
        setFormValue({} as any)
        onClose(response.identity);
      }
    }
  };

  const model = Schema.Model({
    title: StringType().isRequired(t('The name of the document is required.')),
    body: StringType().isRequired(t('Please write some body text.')),
  });

  const audiences = formValue.audience ? formValue.audience.map(o => ({ label: o, value: o })) : [];

  return (
    <Modal backdrop="static" show onHide={() => onClose(false)}>
      <Modal.Header>
        <h5>{contentType === 'dialog' ? t("New conversation") : t("New document")}</h5>
      </Modal.Header>
      {formValue && <Modal.Body>
        {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
        {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
        {!isBusy && <Form fluid className="fluid-tooltip" model={model} ref={form} formValue={formValue} onChange={value => setFormValue(value as any)}>
          <FormGroup>
            <ControlLabel>{t("Title")}</ControlLabel>
            <FormControl name="title" />
            <HelpBlock tooltip>{t("The title is the most important part of your document since will weight the most in the search engine")}</HelpBlock>
          </FormGroup>
          <FormGroup>
            <ControlLabel>{t("Audiences")}</ControlLabel>
            <TagPicker name="audience" creatable data={audiences} value={formValue.audience} onChange={value => formValue.audience = value} placeholder={t("Select or create the document audience...")} />
            <HelpBlock tooltip>{t("You can segment visibility using audiences. If set, this document will be seen only by users that matches at least one audience.")}</HelpBlock>
          </FormGroup>
          {contentType !== 'dialog' && <CustomField
            name="body"
            label="Body"
            height={300}
            accepter={MarkdownEditor}
          />}
        </Form>}
      </Modal.Body>}
      <Modal.Footer>
        <Button disabled={isBusy} onClick={() => onNewItem()} appearance="primary">
          {t("Create")}
        </Button>
        <Button disabled={isBusy} onClick={() => onClose(false)} appearance="subtle">
          {t("Cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const showNewDocumentDialog = (collectionId: string, contentType: string) => {
  let TranslatedNewDocumentDialog = withTranslation()(NewDocumentDialog);
  return new Promise((response, reject) => {
    let div = document.createElement('div');
    let modalRoot = document.getElementById("modal-root");
    ReactDOM.render(<TranslatedNewDocumentDialog collectionId={collectionId} contentType={contentType} onClose={(result: boolean) => {
      response(result);
      ReactDOM.unmountComponentAtNode(div);
      modalRoot!.removeChild(div);
    }} />, div);
    modalRoot!.appendChild(div);
  });
};

export default showNewDocumentDialog