import React, { FC, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { Button, ButtonToolbar, Container, Content, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, Message, Panel, Schema } from 'rsuite';
import HttpService, { formatMessage } from 'src/core/services/http.service';
import { container } from 'src/inversify.config';
import { authenticationService } from 'src/core/services/authentication'
const { StringType } = Schema.Types;

interface LoginModel {
    username: string,
    password: string
}

const LoginPage: FC<RouteComponentProps<any> & WithTranslation> = ({ t, ...props }) => {    
    const httpService = container.get(HttpService);
    const form = useRef<any>();
    const [formValue, setFormValue] = useState<LoginModel>({} as any)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const model = Schema.Model({
        username: StringType().isRequired(t('Please enter your username.')),
        password: StringType().isRequired(t('Please enter your password.'))
    });

    const onLogin = async () => {
        let isValid = await form!.current!.checkAsync();
        if (isValid) {
            try {
                var result = await httpService.post("/api/login/token", formValue)
                if (result.status == 200) {
                    authenticationService.setToken(formValue.username, result.data as string)
                    setFormValue({} as any)
                    window.location.href = `${process.env.PUBLIC_URL}`
                } else {
                    setErrorMessage(t("Invalid username or password"));
                }
            } catch (err) {
                setErrorMessage(formatMessage(err));
            }
        }
    }

    return (
        <Container>
            <Content className="login-page">
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={8}>
                        <Panel header={<h3>{t("Knowledge Management")}</h3>} bordered>
                            {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
                            <Form fluid model={model} ref={form} formValue={formValue} onChange={value => setFormValue(value as any)}>
                                <FormGroup>
                                    <ControlLabel>Username or email address</ControlLabel>
                                    <FormControl name="username" />
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Password</ControlLabel>
                                    <FormControl name="password" type="password" />
                                </FormGroup>
                                <FormGroup>
                                    <ButtonToolbar style={{ textAlign: 'right' }}>
                                        <Button appearance="primary" onClick={onLogin}>Sign in</Button>
                                        {/*<Button appearance="link">Forgot password?</Button>*/}
                                    </ButtonToolbar>
                                </FormGroup>
                            </Form>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    )
}

export default withTranslation()(withRouter(LoginPage))