import React, { FC, useEffect, useMemo, useState } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import { Breadcrumb, Icon } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { container } from 'src/inversify.config';
import { BreadcrumbProvider } from 'src/core/stores/breadcrumb-store';

export interface BreadcrumbProps {
}

const BreadcrumbWidget: FC<BreadcrumbProps & WithTranslation & RouteComponentProps<any>> = ({ t, match }) => {
    const breadcrumbProvider = container.get(BreadcrumbProvider)
    let current = ''
    const paths = match.url ? (match.url.split('/').filter(o => o).slice(0, -1).map(o => ({ path: current = current + '/' + o, name: breadcrumbProvider.getTitle(match.url, current, o) })).filter(o => o.name && o.name != "")) : [];
    /*if (paths.length == 0)
        return <div></div>*/
    return (
        <Breadcrumb style={{ padding: 0, margin: 0, fontSize: 16 }} separator={<Icon icon="angle-right" />}>
            <Breadcrumb.Item componentClass={Link} to="/">
                <Icon icon="home" /> {paths.length == 0 && <span>{t("Home")}</span>}
            </Breadcrumb.Item>
            {paths.map((path, index) => (
                <Breadcrumb.Item key={`crumb${index}`} componentClass={Link} to={path.path}>
                    {path.name}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
}

export default withRouter(withTranslation()(BreadcrumbWidget));