import React, { FC, useState, useMemo, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Icon, Input, InputGroup, List, Message, Nav, SelectPicker } from 'rsuite';
import CodeEditor from 'src/core/ui/form/code-editor'
import yaml from 'js-yaml';
import { LanguagesTabs } from 'src/core/ui/language/languages-tabs';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import { createState, none, State } from '@hookstate/core';

export interface DialogEditorProps {
  value?: string
  width?: number | string
  height?: number | string
  onChange?: (value?: string) => void;
}

interface IntentDefinition {
  intent: string,
  values: {
    key: string,
    value: string
  }[]
}

interface StepDefinition {
  step: string,
  condition?: string,
  action: any,
  turn: 'Continue' | 'Wait' | 'End'
}


interface DialogDefinition {
  intents: IntentDefinition[]
  steps: StepDefinition[]
}

function isNullOrWhiteSpace(str: string) {
  return (!str || str.length === 0 || /^\s*$/.test(str))
}

const TextareaWrapper: FC<{ value: State<string> } & WithTranslation> = ({ value, t }) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  return <Input componentClass="textarea" rows={2} value={value.value} /*onChange={text => { value.set(text); forceUpdate() }}*/ />
}

const LocalizedEditor: FC<{ value: State<{ [key: string]: string }> } & WithTranslation> = ({ value, t, ...props }) => {

  const [languages, setLanguages] = useState<string[]>(() => Object.getOwnPropertyNames(value.value) || [])
  const [language, setLanguage] = useState(() => languages.length > 0 ? languages[0] : 'en');

  const intentValue = useMemo(() => {
    if (value.value.hasOwnProperty(language))
      return value.nested(language)
    return none
  }, [language]);

  const onAddLanguage = async (lang: string) => {
    value[language].set("")
    setLanguages([...languages, lang])
    setLanguage(lang);
    return true;
  }

  const onRemoveLanguage = async (lang: string) => {
    if (languages.length <= 1)
      return false
      if (!await ConfirmDialog({
        text: t("Are you sure want to remove the '{0}' language? Content will be lost.").replace("{0}", lang),
        type: "warning",
        okLabel: t("Yes"),
        cancelLabel: t("No"),
      }))
        return false;

    setLanguages(languages.filter(l => l !== lang));
    value[language].set(none)
    if (lang == language) {
      setLanguage(languages.length > 0 ? languages[0] : 'en');
    }

    return true;
  }

  const onChangeLanguage = async (lang: string) => {
    setLanguage(lang)
  }

  return <div>
    <LanguagesTabs selectedLanguages={languages} availableLanguages={['en', 'es', 'pt']} value={language} onChange={onChangeLanguage} onAddLanguage={onAddLanguage} onRemoveLanguage={onRemoveLanguage} />
    {languages.length > 0 && <TextareaWrapper value={intentValue} t={t} {...props} />}
  </div>
}

const StepDefinitionEditor: FC<{ value: State<StepDefinition> } & WithTranslation> = ({ value, t, ...props }) => {

  const getAction = (actionType: string) => {
    switch (actionType) {
      case 'CallApi':
        return <span>{t('Call the following API')}:</span>
      case 'Thinking':
        return <span>{t('Show thinking indicator')}:</span>
      case 'GoTo':
        return <span>{t('Go to the following step')}:</span>
      case 'Switch':
        return <span>{t('Switch to the following dialog')}:</span>
      case 'Ask':
      case 'Say':
      default:
        return <span><strong>{t(actionType)}</strong>:</span>
    }
  }

  const getCondition = (condition: string) => {
    if (isNullOrWhiteSpace(condition)) {
      return <span style={{ paddingRight: 5 }}><strong>{t('Always')}</strong></span>
    }
    return <span>{t("When")}<Button style={{ padding: 5, paddingTop: 3 }} appearance='link'><strong>{condition}</strong></Button>{t("then")}&nbsp;</span>
  }

  const turnData = [
    { label: t('Continue'), value: 'Continue' },
    { label: t('Wait'), value: 'Wait' },
    { label: t('End'), value: 'End' },
  ]

  const getEditor = (action: State<any>) => {
    switch (action.value.type) {
      case 'CallApi':
        return <div style={{ width: '100%' }}>
          <InputGroup>
            <InputGroup.Addon>{value.action.nested('method' as any).value}</InputGroup.Addon>
            <Input value={value.action.nested('uri' as any).value} />
          </InputGroup>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t("Then save the answer in")}<Input value={value.action.nested('entity' as any).value} style={{ width: 250, margin: 5 }} />{t("and")}<SelectPicker style={{ width: 120, margin: 5 }} allo data={turnData} value={value.nested('turn' as any).value} />
          </div>
        </div>
      case 'Thinking':
        return <></>
      case 'GoTo':
        return <span>{t('Go to the following step')}:</span>
      case 'Switch':
        return <span>{t('Switch to the following dialog')}:</span>
      case 'Ask':
        return <div style={{ width: '100%' }}>
          {(value.action as any).questions.value && (value.action as any).questions.value.length > 0 && <div>
            {(value.action.nested('questions' as any) as any).map((item: any) => (<span>{item.es.value}</span> /*<LocalizedEditor value={item} t={t} {...props} />*/))}
          </div>}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t("Then save the answer in")}<Input value={value.action.nested('entity' as any).value} style={{ width: 250, margin: 5 }} />{t("and")}<SelectPicker style={{ width: 120, margin: 5 }} allo data={turnData} value={value.nested('turn' as any).value} />
          </div>
        </div>
      case 'Say':
        return <div style={{ width: '100%' }}>
          <span>{value.action.nested('text' as any).value.es}</span>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t("And")}<SelectPicker style={{ width: 120, margin: 5 }} allo data={turnData} value={value.nested('turn' as any).value} />
          </div>
        </div>
      /*return <LocalizedEditor value={(value.action.nested('text' as any) as any)} t={t} {...props} />*/
      default:
        return <span>TODO:</span>
    }
  }

  return <div style={{ verticalAlign: 'middle'}}>
    {getCondition(value?.condition.value || "")}{getAction(value.action.value.type)}
    {getEditor(value.action)}
  </div>
}

const InputWrapper: FC<{ value: State<string> } & WithTranslation> = ({ value, t }) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  return <Input name="intent_value" placeholder={t("Start writing your intent...")} value={value.value} /*onChange={text => { value.set(text); forceUpdate() }}*/ />
}

const IntentDefinitionEditor: FC<{ value: State<IntentDefinition> } & WithTranslation> = ({ value, t, ...props }) => {
  const [languages, setLanguages] = useState<string[]>(() => value.values.value.groupBy(o => o.key).map(o => o.key) || [])
  const [language, setLanguage] = useState(() => languages.length > 0 ? languages[0] : 'en');
   
  const intentValue = useMemo(() => {
    var result = value.value && value.values.value ? value.values.filter(o => o.key.value == language) : []
    if (result.length > 0)
      return result[0].nested('value')
    return none
  }, [language]);

  const onAddLanguage = async (lang: string) => {
    value.values[value.values.length].set({ key: lang, value: ""})
    setLanguages([...languages, lang])
    setLanguage(lang);
    return true;
  }

  const onRemoveLanguage = async (lang: string) => {
    if (languages.length <= 1)
      return false

    const samples = value.values.value ? value.values.filter(o => o.key.value == lang) : []

    if (samples.length > 0) {
      if (!await ConfirmDialog({
        text: t("Are you sure want to remove the '{0}' language? Content will be lost.").replace("{0}", lang),
        type: "warning",
        okLabel: t("Yes"),
        cancelLabel: t("No"),
      }))
        return false;
    }

    setLanguages(languages.filter(l => l !== lang));
    samples.forEach(sample => sample.set(none));
    if (lang == language) {
      setLanguage(languages.length > 0 ? languages[0] : 'en');
    }

    return true;
  }

  const onChangeLanguage = async (lang: string) => {
    setLanguage(lang)
  }

  return <div>
    <LanguagesTabs selectedLanguages={languages} availableLanguages={['en', 'es', 'pt']} value={language} onChange={onChangeLanguage} onAddLanguage={onAddLanguage} onRemoveLanguage={onRemoveLanguage} />
    {languages.length > 0 && <InputWrapper value={intentValue} t={t} {...props} />}
  </div>
}

const DialogEditor: FC<DialogEditorProps & WithTranslation> = ({ value, width, height, onChange, t, ...props }) => {
  const [activeTab, setActiveTab] = useState<string>("dialog")
  const [error, setError] = useState<string>()

  useEffect(() => {
    let newValue = yaml.dump(dialog.value)
    if (value != newValue && onChange)
      onChange(newValue)
  }, [activeTab])

  const dialog = useMemo<State<DialogDefinition>>(() => {
    let obj: DialogDefinition = {} as DialogDefinition;
    try {
      obj = (yaml.load(value || "") || {}) as DialogDefinition;
      setError(undefined)
    }
    catch (msg) {
      setError(JSON.stringify(msg))
      obj = {} as DialogDefinition;
    }
    if (!obj.intents)
      obj.intents = []
    if (!obj.steps)
      obj.steps = []
    return createState(obj)
  }, [value])

  return <div style={{ width: width, minHeight: height }}>
    {error && <Message type="error" description={error} />}
    <Nav appearance="tabs" activeKey={activeTab} onSelect={key=>setActiveTab(key)}>
      <Nav.Item eventKey="dialog"><Icon icon="comments-o" />{t("Conversation")}</Nav.Item>
      <Nav.Item eventKey="intents"><Icon icon="hashtag" />{t("Intents")}</Nav.Item>
      <Nav.Item eventKey="source"><Icon icon="file-text-o" />{t("Source code")}</Nav.Item>
    </Nav>
    {activeTab == "dialog" && <div>
      <List bordered hover>
        {dialog?.steps?.map((item: State<StepDefinition>, index: number) => (
          <List.Item key={`step_${index}`} index={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '250%', marginRight: 20 }}>{String.fromCharCode(9312 + index)}</span>
              <div style={{ width: '100%' }}>
                <StepDefinitionEditor value={item} t={t} {...props} />
              </div>
            </div>
          </List.Item>
        ))}
      </List>
    </div>}
    {activeTab == "intents" && <div>
      <List bordered hover>
        {dialog?.intents?.map((item: State<IntentDefinition>, index: number) => (
          <List.Item key={`intent_${index}`} index={index}>
            <strong><Icon icon="hashtag" /> {item.intent.value}</strong>
            <IntentDefinitionEditor value={item} t={t} {...props} />
          </List.Item>
        ))}
      </List>
    </div>}
    {activeTab == "source" && <CodeEditor language="yaml" value={value} onChange={value => onChange && onChange(value || '')} height={height} width={width} />}
  </div>
}

export default withTranslation()(DialogEditor);