import React, { FC } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { FlexboxGrid, Panel, Icon, Button } from 'rsuite';
import PageContainer from 'src/core/ui/frame/page-content';
import ActivityDashboard from 'src/pages/insights/dashboards/activity-dashboard'
import LowScoreDashboard from 'src/pages/insights/dashboards/lowscore-dashboard'
import HeroButton from 'src/core/ui/hero-button'

const HomePage: FC<RouteComponentProps<any> & WithTranslation> = ({ t, ...props }) => {
    return (
        <PageContainer
            title={t("Welcome")}>
            <FlexboxGrid style={{ marginBottom: 10, paddingTop: 10 }}>
                <FlexboxGrid.Item>
                    <HeroButton icon='book' href='/collections' width={250} height={100} title={t('Collections')} description={t('Manage your Knowledge base collections')} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <HeroButton icon='language' href='/synonyms' width={250} height={100} title={t('Synonyms')} description={t('Manage synonyms transformation lists')} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <HeroButton icon='search' href='/endpoints' width={250} height={100} title={t('Search endpoints')} description={t('Manage search endpoints and configurations')} />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            
            <ActivityDashboard />
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={24}>
                    <LowScoreDashboard />
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </PageContainer>
    )
}

export default withTranslation()(withRouter(HomePage))