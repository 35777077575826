import React, { FC } from 'react';
import { Route, withRouter, Switch, RouteComponentProps } from 'react-router';
import DocumentsDetailPage from './document-detail';

const DocumentsIndex: FC<RouteComponentProps<any>> = ({ match }) => {
    return <Switch>
        <Route exact path={`${match.path}/:documentId`} component={DocumentsDetailPage} />
    </Switch>
}

export default withRouter(DocumentsIndex)