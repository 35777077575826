import React, { useEffect } from 'react'
import './languages-tabs.css'
import { Dropdown, Icon, Loader, Nav } from 'rsuite';
import ISO6391 from 'iso-639-1';
import ReactCountryFlag from "react-country-flag";

export interface LanguagesTabsProps {
    /** Idioma selecionado en formato ISO 639-1 (dos letras) */
    value: string
    /** Evento que se lanza al seleccionar un idioma */
    onChange: (value: string) => void
    /** Lista de idiomas disponibles (es, en, pt, ...) */
    availableLanguages?: string[]
    /** Lista de idiomas visibles en el selector (es, en) */
    selectedLanguages: string[]
    /** Evento que se lanza al borrar un idioma del selector */
    onRemoveLanguage: (lang: string) => Promise<boolean>
    /** Evento que se lanza al agregar un idioma al selector */
    onAddLanguage: (lang: string) => Promise<boolean>
}

export const LanguagesTabs: React.FC<LanguagesTabsProps> = ({ availableLanguages, selectedLanguages, value, onChange, onAddLanguage, onRemoveLanguage}) => {
    const [loading, SetLoading] = React.useState(false);
    const [availables, setAvailables] = React.useState(availableLanguages ?? []);
    const [selectables, setSelectables] = React.useState(selectedLanguages ?? []);
    const [active, setActive] = React.useState(value);

    useEffect(() => {
        setAvailables(availableLanguages || [])
        setSelectables(selectedLanguages || [])
        setActive(value)
    }, [availableLanguages, selectedLanguages, value])

    if (!availableLanguages)
        availableLanguages = ['en', 'es', 'pt']

    const getLanguageName = (lang: string) => {
        if (!lang) return lang;
        return ISO6391.getName(lang);
    }

    const getCountryCode = (lang: string) => {
        if (lang === 'en')
            lang = 'gb';
        return lang;
    }

    const SelectedLanguages = () => {
        return (
            <React.Fragment>
                {selectables && selectables.map(lang => (
                    <SelectedLanguageItem key={lang} lang={lang} />
                ))}
            </React.Fragment>
        );
    }

    const SelectedLanguageItem = ({ lang }: { lang: string }) => {
        const languageName = getLanguageName(lang);

        return <Nav.Item
            active={lang === active}
            key={lang}
            onSelect={eventKey => {
                if (active !== eventKey) {
                    setActive(eventKey);
                    onChange(eventKey);
                }
            }}
            eventKey={lang}>
            <div className='language'>
                <ReactCountryFlag className="flag" countryCode={getCountryCode(lang)} svg />
                <span>{languageName} ({lang})</span>
                <span className='delete' onClick={e => onItemRemove(lang)}>
                    <Icon icon="close" />
                </span>
            </div>
        </Nav.Item>;
    }

    const AvailableLanguages = () => {
        if (!availables || availables.length === 0) return null;

        let list = availables;

        if (selectables) {
            list = availables.filter((item) => {
                return selectables.indexOf(item) === -1;
            });
        }

        if (list.length === 0) return null;

        return (
            <Dropdown icon={<Icon icon="plus" />} title="Añadir idioma">
                {list && list.map(lang => (
                    <AvailableLanguageItem key={lang} lang={lang} />
                ))}
            </Dropdown>
        );
    }

    const AvailableLanguageItem = ({ lang }: { lang: string }) => {
        const languageName = getLanguageName(lang);

        return <Dropdown.Item
            eventKey={lang}
            key={lang}
            onSelect={onItemAdd}>
            <div className='language'>
                <ReactCountryFlag className="flag" countryCode={getCountryCode(lang)} svg />
                <span>{languageName} ({lang})</span>
            </div>
        </Dropdown.Item>
    }

    const onItemAdd = async (lang: string, e: any) => {
        SetLoading(true);
        var result = await onAddLanguage(lang);
        SetLoading(false);

        if (result && result === true) {
            const list = [...selectables, lang];
            setSelectables(list);
            setActive(lang);
            onChange(lang);
        }
    }

    const onItemRemove = async (lang: string) => {
        SetLoading(true);
        var result = await onRemoveLanguage(lang);
        SetLoading(false);

        if (result && result === true) {
            //console.log(result);
            const list = [...selectables];
            list.splice(list.map(item => item).indexOf(lang), 1);
            setSelectables(list);
            const activeTab = list[0] ? list[0] as any : null;
            setActive(activeTab);
            onChange(activeTab);
        }
    }

    return (
        <div className='language-tabs-wrapper'>
            <Nav>
                <SelectedLanguages />
                <AvailableLanguages />
                {loading &&
                    <Nav.Item key='loading' eventKey='loading'>
                        <Loader />
                    </Nav.Item>
                }
            </Nav>
        </div>
    )
}