import React, { FC, useEffect, useRef, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withIdentity, IdentityProps } from 'src/core/services/authentication';
import { clone, formatDate, formatDateTime, formatTimeSpent } from 'src/core/utils/object';
import { container } from 'src/inversify.config';
import { CrawlerPatchSummary, CrawlerPatchesSummaryStore } from 'src/stores/crawlerPatches-store';
import { Query } from 'src/core/stores/data-store';
import { Button, Icon, InputGroup, Message, Tooltip, Whisper, Uploader, Alert, Loader } from 'rsuite';
import { TableModel } from 'src/core/ui/table/table-model';
import { formatMessage } from 'src/core/services/http.service';
import Table from 'src/core/ui/table';
import RegionContainer from 'src/core/ui/frame/region-content';
import FlexboxGridItem from 'rsuite/lib/FlexboxGrid/FlexboxGridItem';
import SearchBox from 'src/core/ui/form/search-box';
import { Link } from 'react-router-dom';
import ContextMenu from 'src/core/ui/table/context-menu';
import ConfirmDialog from 'src/core/ui/dialogs/confirm-dialog';
import showCrawlerPatchDialog from './crawler-patch-detail';

const CrawlerPatchesPage: FC<{ collectionId: string, crawlerId: string } & WithTranslation & IdentityProps> = ({ t, collectionId, crawlerId, identity, ...props }) => {
    const crawlerPatchesStore = container.get(CrawlerPatchesSummaryStore)
    crawlerPatchesStore.setCrawler(collectionId, crawlerId)
    const crawlerPatchState = crawlerPatchesStore.state
    const [uploading, setUploading] = useState(false)

    const [query, setQuery] = useState({
        searchQuery: '',
        orderBy: [{ field: 'title', direction: 'Ascending', useProfile: false }],
        skip: 0,
        take: 50
    } as Query)

    const onChange = async (query: Query) => {
        crawlerPatchesStore.load(query)
        setQuery(query)
    }

    const onSearch = async (q?: string) => {
        let newQuery = clone(query || {}) as Query;
        newQuery.skip = 0;
        newQuery.searchQuery = q || "";
        await onChange(newQuery);
    }

    const onDeleteDocument = async (crawlerPatchId: string) => {
        await crawlerPatchesStore.delete(crawlerPatchId)
    }

    useEffect(() => {
        crawlerPatchesStore.load(query)
    }, [query]);

    const tableModel = {
        query: query,
        loading: crawlerPatchState.isBusy.value,
        columns: [
            {
                field: 'icon',
                width: 40,
                title: "",
                renderer: (item) => <Icon icon="book" />
            },
            {
                width: 300,
                field: 'documentId',
                title: t('Apply to'),
                renderer: (item) => <Button appearance={'link'} onClick={() => showCrawlerPatchDialog(collectionId, crawlerId, item.id)}><span>{item.documentId}</span></Button>
            },
            {
                flow: 1,
                field: 'title',
                title: t('Title'),
                renderer: (item) => <span>{item.title}</span>
            },
            {
                field: 'createdBy',
                title: t('Created by'),
                renderer: (item) => <span>{item.createdBy && item.createdBy.identityDisplayName}</span>
            },
            {
                field: 'createdOn',
                title: t('Created on'),
                renderer: (item) => <span>{formatDate(item.createdOn)}</span>
            },
            {
                field: 'modifiedBy',
                title: t('Modified by'),
                renderer: (item) => <span>{item.modifiedBy && item.modifiedBy.identityDisplayName}</span>
            },
            {
                field: 'modifiedOn',
                title: t('Modified on'),
                renderer: (item) => <span>{formatDate(item.modifiedOn)}</span>
            },
            {
                field: 'actions',
                width: 60,
                title: "",
                renderer: (item) => <ContextMenu
                    entries={[
                        //{
                        //    title: <div><Icon icon="download" /> {t("Download a copy")}</div>,
                        //    onSelect: () => {

                        //    }
                        //},
                        //{ divider: true },
                        {
                            title: <div><Icon icon="trash2" /> {t("Delete crawlerPatch")}</div>, onSelect: () => {
                                ConfirmDialog({
                                    text: t("Are you sure want to delete the selected crawlerPatch?"),
                                    type: "warning",
                                    okLabel: t("Yes"),
                                    cancelLabel: t("No"),
                                }).then(result => {
                                    if (result) {
                                        onDeleteDocument(item.id);
                                    }
                                });
                            }
                        },
                    ]}>
                    <Link to="#"><Icon icon="more" /></Link>
                </ContextMenu>
            },
        ],
        data: crawlerPatchState,
        paginable: true
    } as TableModel<CrawlerPatchSummary>;
    
    return (
        <RegionContainer
            toolbarLeft={
                [
                    <Whisper key='uploadDocument' placement="top" trigger="hover" speaker={<Tooltip>{t("Upload crawler patches")}</Tooltip>}>
                        <Uploader
                            disabledFileItem
                            fileListVisible={false}
                            multiple={false}
                            withCredentials
                            action={`/api/collections/${collectionId}/crawlers/${crawlerId}/patches?access_token=${identity && identity.accessToken}`}
                            style={{ display: 'inline-block' }}
                            onUpload={() => setUploading(true)}
                            onSuccess={() => { setUploading(false); Alert.info(t('The document was upload successfully.')); onChange(query) }}
                            onError={() => { setUploading(false); Alert.error(t('An error has occurred while uploading the file.')) }}>
                            <Button placement="left" appearance="primary">
                                <Icon icon="file-upload" />
                            </Button>
                        </Uploader>
                    </Whisper>,
                    <Whisper key='reload' placement="top" trigger="hover" speaker={<Tooltip>{t("Reload list")}</Tooltip>}>
                        <Button placement="left" onClick={() => onChange(query)}>
                            <Icon icon="reload" />
                        </Button>
                    </Whisper>
                ]
            }
            toolbarRight={
                [
                    <FlexboxGridItem key="search">
                        <InputGroup inside>
                            <SearchBox key='searchBox' onSearch={onSearch} />
                        </InputGroup>
                    </FlexboxGridItem>
                ]
            }>

            {uploading && <Loader center backdrop size="md" content={t("Uploading file...")} />}
            {crawlerPatchState.errorMessage.value && <Message type="error" description={formatMessage(crawlerPatchState.errorMessage.value)} />}
                <Table model={tableModel} onChange={onChange} />
        </RegionContainer>
    )
}

export default withIdentity(withTranslation()(CrawlerPatchesPage))