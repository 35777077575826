import React, { FC, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Form, FormGroup, FormControl, ControlLabel, Modal, Message, Loader, Schema, HelpBlock } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import { container } from 'src/inversify.config';
import { EndpointItemStore, NewEndpointItem } from 'src/stores/endpoints-store';
import { formatMessage } from 'src/core/services/http.service';
const { StringType } = Schema.Types;

interface NewEndpointsDialogProps {
    onClose: (result: boolean) => void
}

const NewEndpointsDialog: FC<WithTranslation & NewEndpointsDialogProps> = ({ t, onClose }) => {
    const newEndpointStore = container.get(EndpointItemStore)
    const newEndpointState = newEndpointStore.state;
    const isBusy = newEndpointState.isBusy.value
    const errorMessage = newEndpointState.errorMessage.value
    const form = useRef<any>();
    const [formValue, setFormValue] = useState<NewEndpointItem>({} as any)

    const transformedName = useMemo(() => {
        let title = (formValue.title || "").replace(/\s\s+/g, ' ').toLowerCase();
        title = title.replace(/\s/gi, '-')
        title = title.replace(/ñ/gi, 'n')
        title = title.replace(/[^0-9a-z_-]/gi, '')
        return title;
    }, [formValue && formValue.title])

    const onNewItem = async () => {
        let validation = await form!.current!.checkAsync();
        if (validation && !validation.hasError) {
            formValue.title = transformedName
            let response = await newEndpointStore.create(formValue);
            if (response) {
                setFormValue({} as any)
                onClose(true);
            }
        }
    };

    const model = Schema.Model({
        title: StringType().isRequired(t('The name of the endpoint is required.'))
    });

    return (
        <Modal backdrop="static" show onHide={() => onClose(false)}>
            <Modal.Header>
                <h5>{t("New Search Endpoint")}</h5>
            </Modal.Header>
            <Modal.Body>
                {isBusy && <Loader center backdrop size="md" content={t("Loading...")} />}
                {errorMessage && <Message type="error" description={formatMessage(errorMessage)} />}
                {formValue.title && transformedName !== formValue.title && <Message description={<span>{t('The title will be rewritten as:')} <b>{transformedName}</b></span>} />}
                {!isBusy && <Form fluid className="fluid-tooltip" model={model} ref={form} formValue={formValue} onChange={value => setFormValue(value as any)}>
                    <FormGroup>
                        <ControlLabel>{t("Title")}</ControlLabel>
                        <FormControl name="title" />
                        <HelpBlock tooltip>{t("The name of the endpoint you want to create")}</HelpBlock>
                    </FormGroup>
                </Form>}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isBusy} onClick={() => onNewItem()} appearance="primary">
                    {t("Create")}
                </Button>
                <Button disabled={isBusy} onClick={() => onClose(false)} appearance="subtle">
                    {t("Cancel")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const showNewEndpointsDialog = () => {
    let TranslatedNewEndpointsDialog = withTranslation()(NewEndpointsDialog);
    return new Promise((response, reject) => {
        let div = document.createElement('div');
        let modalRoot = document.getElementById("modal-root");
        ReactDOM.render(<TranslatedNewEndpointsDialog onClose={(result: boolean) => {
            response(result);
            ReactDOM.unmountComponentAtNode(div);
            modalRoot!.removeChild(div);
        }} />, div);
        modalRoot!.appendChild(div);
    });
};

export default showNewEndpointsDialog