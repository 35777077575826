import React, { FC, useEffect, useState } from 'react';
import { SelectPicker, Panel, Loader, Placeholder, Table } from 'rsuite';
import { withTranslation, WithTranslation } from 'react-i18next';
import HttpService from 'src/core/services/http.service';
import { container } from 'src/inversify.config';

let Column = Table.Column;
let HeaderCell = Table.HeaderCell;
let Cell = Table.Cell;

export interface LowerScoreDashboardProps {

}

const LowerScoreDashboard: FC<LowerScoreDashboardProps & WithTranslation> = ({ t }) => {
    const httpService = container.get(HttpService);
    const [isBusy, setIsBusy] = useState(true);
    const [items, setItems] = useState<any>([]);

    const onLoad = async () => {
        try {
            setIsBusy(true);
            var result = await httpService.get<any>(`/api/admin/statistics/lowscore`);

            let table = result.data.tables[0];
            let rows = table.rows;

            var items = rows.orderByDescending((o: any) => o[1]).map((row: any) => {
                return {
                    intent: row[5],
                    documentId: row[6],
                    title: row[8] ?? row[6],
                    score: row[7]
                } as any;
            });

            setItems(items)
            setIsBusy(false);
        } finally {
            setIsBusy(false);
        }
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <Panel header={<label style={{ fontSize: 14 }}>{t("Inquiries with lower score in last 7 days")}</label>} bordered style={{ padding: 10, width: '100%', marginTop: 10 }}>
            {isBusy && <Loader />}
            {!isBusy && items && <Table data={items} height={400}>
                <Column flexGrow={1}>
                    <HeaderCell>{t("Inquiries")}</HeaderCell>
                    <Cell dataKey="intent" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>{t("Document")}</HeaderCell>
                    <Cell dataKey="title" />
                </Column>
                <Column width={100} fixed>
                    <HeaderCell>{t("Score")}</HeaderCell>
                    <Cell dataKey="score" />
                </Column>
            </Table>}
        </Panel>
    );
}

export default withTranslation()(LowerScoreDashboard);